import {
  Component,
  Input,
  OnDestroy,
  OnInit,
  ViewEncapsulation,
} from '@angular/core';
import { Location } from '@angular/common';
import { MatDialog } from '@angular/material/dialog';
import { Router } from '@angular/router';
import { COURSE_LANGUAGE } from 'src/app/courses/constants';
import { ICourseReadModel, PublishStatus } from 'src/app/courses/courses';
import { UserCourseApiService } from 'src/app/core/course/user-course-api-service';
import { StringHelpers } from 'src/app/shared/helpers';
import {
  CourseListService,
  ListInteraction,
} from '../../core/course/lists-interaction.service';
import { ViewCoursePreviewComponent } from '../../courses/list-page/view-preview/view-preview.component';
import { BreakpointObserver, BreakpointState } from '@angular/cdk/layout';
import { Observable, Subject, combineLatest, takeUntil } from 'rxjs';
import { UsersService } from '../../user/users.service';
import { AskToLoginComponent } from '../../core/ask-to-login-dialog/ask-to-login.component';
import { ILabelReadModel } from '../label';
import { CourseCardHelper } from './helper';
import { DataLayerService } from 'src/app/core/data-layer.service';
import { UserAdmin } from 'src/app/user/admin';

@Component({
  selector: 'course-list-card',
  templateUrl: 'course-list-card.component.html',
  styleUrls: ['course-list-card.component.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class CourseListCardComponent implements OnInit, OnDestroy {
  @Input() public course!: ICourseReadModel;

  public averageLessonLength: number = 0;

  public visibleLessonsCount: number = 0;
  public twoTopics!: ILabelReadModel[];

  public isComingSoon: boolean = false;

  public showCTA?: boolean;
  private touchStartY?: number;
  private touchStartX?: number;

  public isSmallScreen: Observable<BreakpointState> =
    this.breakpointObserver.observe(['(max-width: 768px)']);

  public isAdminShown = false;

  private destroyNotifier = new Subject<void>();

  constructor(
    private courseListService: CourseListService,
    private userCourseApiService: UserCourseApiService,
    private router: Router,
    public dialog: MatDialog,
    private breakpointObserver: BreakpointObserver,
    private usersService: UsersService,
    private dataLayerService: DataLayerService,
    private urlLocation: Location,
  ) {}

  public ngOnInit(): void {
    if (!this.course) {
      this.averageLessonLength = 0;
      this.visibleLessonsCount = 0;
      return;
    }
    this.visibleLessonsCount =
      this.course?.lessons.filter((l) => !l.hiddenAtUtc).length || 0;
    this.setAverageCourseDuration();

    this.isComingSoon = this.course.publishStatus === PublishStatus.ComingSoon;
    // Use it instead of slice pipe to avoid continuous re-rendering
    this.twoTopics = this.course.topics.slice(0, 2);

    combineLatest([
      this.usersService.isAdminInterfaceShown,
      this.usersService.userAdmin,
    ])
      .pipe(takeUntil(this.destroyNotifier))
      .subscribe(
        ([isAdminInterfaceShown, admin]: [boolean, UserAdmin]) =>
          (this.isAdminShown = isAdminInterfaceShown && admin.isSuperAdmin),
      );
  }

  public onToggleFavoriteStatus(event: any, value: boolean): void {
    event.preventDefault();
    event.stopImmediatePropagation();

    if (!this.usersService.getUserId()) {
      this.askUserToSignIn();
      return;
    }

    this.course.courseForCurrentUser!.isOnFavorite = value;

    if (!!value) {
      this.courseListService.sendNewInteraction({
        listTitle: 'myFavorite',
        course: { ...this.course },
        interaction: ListInteraction.UserAddedOnFavorite,
      });
    } else {
      this.courseListService.sendNewInteraction({
        listTitle: 'myFavorite',
        course: { ...this.course },
        interaction: ListInteraction.UserRemovedFromFavorite,
      });
    }

    this.userCourseApiService
      .patchUserCourse(this.course.id, { isOnFavorite: value })
      .subscribe();
  }

  public askUserToSignIn(): void {
    this.dialog.open(AskToLoginComponent, {
      width: '400px',
    });
  }

  private getUrlNamePath(title: string): string {
    return StringHelpers.getDashSeparatedString(title);
  }

  public navigateToCourse(): void {
    if (this.isComingSoon) {
      return;
    }

    const parsedTitle = this.getUrlNamePath(this.course.title);
    if (this.course.courseForCurrentUser?.completedPercentage) {
      this.router.navigate([
        'courses',
        `${parsedTitle}`,
        `${this.course.id}`,
        'course',
      ]);
      return;
    }

    this.router.navigate(['courses', `${parsedTitle}`, `${this.course.id}`]);
  }

  public continueCourse(): void {
    const parsedTitle = this.getUrlNamePath(this.course.title);
    this.router.navigate([
      'courses',
      `${parsedTitle}`,
      `${this.course.id}`,
      'continue',
    ]);
  }

  public openTeaserVideo($event: any): void {
    $event.stopImmediatePropagation();

    const path = `courses/${StringHelpers.getDashSeparatedString(
      this.course.title,
    )}/${this.course.id}/teaser`;

    const url = `${window.location.origin}/${path}`;

    this.urlLocation.replaceState(path);

    const pageEvent = {
      page_title: 'Course Teaser - Future Females',
      page_location: url,
      page_path: `/${path}`,
    };

    this.dataLayerService.logPageView(pageEvent);

    const dialogRef = this.dialog.open(ViewCoursePreviewComponent, {
      maxWidth: '1440px',
      width: '100%',
      data: {
        language: COURSE_LANGUAGE,
        videoUrl: this.course.teaserUrl,
        captionUrl: this.course.captionUrl,
      },
      panelClass: 'course-preview-dialog-container',
    });

    dialogRef
      .afterClosed()
      .pipe(takeUntil(this.destroyNotifier))
      .subscribe((_) => {
        const path = `courses`;
        const url = `${window.location.origin}/${path}`;

        this.urlLocation.replaceState(path);

        const pageEvent = {
          page_title: 'Courses - Future Females',
          page_location: url,
          page_path: `/${path}`,
        };

        this.dataLayerService.logPageView(pageEvent);
      });
  }

  public onTouchstart(event: any) {
    const evt =
      typeof event.originalEvent === 'undefined' ? event : event.originalEvent;
    const touch = evt.touches[0] || evt.changedTouches[0];
    this.touchStartY = touch.screenY;
    this.touchStartX = touch.screenX;
  }

  public onTouchend(event: any) {
    event.preventDefault();

    try {
      const evt =
        typeof event.originalEvent === 'undefined'
          ? event
          : event.originalEvent;
      const touch = evt.touches[0] || evt.changedTouches[0];

      if (!this.touchStartY || !this.touchStartX) {
        return;
      }

      const delta =
        Math.abs(this.touchStartY - touch.screenY) +
        Math.abs(this.touchStartX - touch.screenX);

      // Show CTA if the user moved the finger while clicking not more than 5pixels
      if (delta < 5) {
        this.showCTA = !this.showCTA;
      }
    } catch (_) {}

    this.touchStartY = undefined;
    this.touchStartX = undefined;
  }

  public onCloseClickOrTouch(event: MouseEvent | TouchEvent) {
    event.preventDefault();

    this.touchStartY = undefined;
    this.touchStartX = undefined;
    this.showCTA = false;
  }

  public onCardClicked() {
    this.navigateToCourse();
  }

  public onMouseEnter(event: any) {
    if (this.isComingSoon) {
      return;
    }

    const vw = Math.max(
      document.documentElement.clientWidth || 0,
      window.innerWidth || 0,
    );
    if (vw < 577) {
      return;
    }
    if (
      event &&
      event.sourceCapabilities &&
      event.sourceCapabilities.firesTouchEvents
    ) {
      return;
    }

    this.showCTA = true;
  }

  public onMouseLeave(event: any) {
    if (this.isComingSoon) {
      return;
    }

    const vw = Math.max(
      document.documentElement.clientWidth || 0,
      window.innerWidth || 0,
    );
    if (vw < 577) {
      return;
    }
    if (
      event &&
      event.sourceCapabilities &&
      event.sourceCapabilities.firesTouchEvents
    ) {
      return;
    }

    this.showCTA = false;
  }

  public trackById(index: number, el: ILabelReadModel): string {
    return el.id;
  }

  private setAverageCourseDuration(): void {
    this.averageLessonLength = CourseCardHelper.getAverageDuration(this.course);
  }

  public ngOnDestroy(): void {
    this.destroyNotifier.next();
    this.destroyNotifier.complete();
  }
}
