<div class="d-flex flex-wrap w-100">
  <mat-form-field
    style="flex-grow: 1"
    class="search-bar-field me-2 my-1"
    appearance="fill"
    floatLabel="never"
    color="default"
  >
    <mat-icon matPrefix>search</mat-icon>
    <mat-label> Search </mat-label>
    <input matInput [formControl]="search" />
  </mat-form-field>

  <div *ngIf="configuration.location" class="d-none d-sm-inline me-2">
    <location-dropdown
      [cityCountries]="cities"
      [selectedLocation]="location"
      [genericLocationResultCount]="genericLocationResultCount"
      (locationChange)="onLocationChange($event)"
    ></location-dropdown>
  </div>

  <div *ngIf="configuration.date" class="d-none d-sm-inline me-2">
    <calendar-filter
      class="me-4"
      [intervals]="intervals"
      [selectedInterval]="interval"
      (interval)="onDateIntervalChange($event)"
    ></calendar-filter>
  </div>

  <div
    class="d-none d-sm-flex results-count-section align-items-center justify-content-around me-2"
    [class.disabled]="resetDisabled"
  >
    <div class="me-2 text-nowrap">
      <span class="results-count">{{ resultsCounts }}</span>
      <span [ngPlural]="resultsCounts">
        <ng-template ngPluralCase="=1"> {{ feature }}</ng-template>
        <ng-template ngPluralCase="other"> {{ featurePlural }}</ng-template>
      </span>
    </div>
    <div class="reset-icon pointer" (click)="clearFilters()">
      <mat-icon>close</mat-icon>
    </div>
  </div>
</div>
