import { ImgCropperConfig } from '@alyle/ui/image-cropper';
import { Component, Inject } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { ImageHelper } from '../../image-cropper/helper';
import { FacilitatorType, IFacilitatorCreate } from '../speaker';

@Component({
  templateUrl: 'add-speaker-dialog.component.html',
  styleUrls: ['add-speaker-dialog.component.scss'],
})
export class AddSpeakerDialogComponent {
  public faciliatorForm: FormGroup;
  public file!: File | null;
  public cropperImage: string = '';
  public croppedImage: string = '';

  public profilePreviewUrl: string | null = null;
  public errors: string[] = [];

  public speakerCropperConfig: ImgCropperConfig = {
    width: 250,
    height: 300,
    type: 'image/jpeg',
    output: ImageHelper.CropOutputSize.speaker,
  };

  constructor(
    @Inject(MAT_DIALOG_DATA) public data: { addAsHost: boolean },
    public dialogRef: MatDialogRef<AddSpeakerDialogComponent>,
  ) {
    this.faciliatorForm = new FormGroup(
      {
        name: new FormControl('', Validators.required),
        description: new FormControl('', Validators.required),
        bio: new FormControl('', Validators.required),
        isHost: new FormControl(false, Validators.required),
        title: new FormControl('', Validators.required),
        company: new FormControl('', Validators.required),
      },
      Validators.required,
    );
  }

  public onProfilePictureSelected({
    image,
    extension,
  }: {
    image: string;
    extension: string;
  }): void {
    const imageName = `${
      this.faciliatorForm.get('name')!.value
    }_profile-picture.${extension}`.replace(' ', '');
    const fileToReturn = ImageHelper.base64ToFile(image, imageName);

    this.file = fileToReturn;
    this.cropperImage = '';
    this.croppedImage = image;
  }

  public clearProfilePicture(): void {
    this.file = null;
    this.profilePreviewUrl = null;
  }

  public onSubmitAttempted(): void {
    this.errors = [];
    this.faciliatorForm.markAllAsTouched();
    this.faciliatorForm.updateValueAndValidity();

    if (!this.file) {
      this.errors = ['No photo uploaded for speaker'];
    }

    if (!this.faciliatorForm.valid) {
      this.errors = [...this.errors, 'Not all required fields are completed'];
    }

    if (this.errors.length > 0) {
      return;
    }

    const faciliator: IFacilitatorCreate = {
      name: this.faciliatorForm.get('name')?.value,
      description: this.faciliatorForm.get('description')?.value,
      bio: this.faciliatorForm.get('bio')?.value,
      profilePhotoFile: this.file!,
      type:
        this.data.addAsHost === true
          ? FacilitatorType.Host
          : FacilitatorType.Speaker,
      title: this.faciliatorForm.get('title')?.value,
      company: this.faciliatorForm.get('company')?.value,
    };
    this.dialogRef.close(faciliator);
  }

  public onCancel(): void {
    this.dialogRef.close();
  }
}
