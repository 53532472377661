import {
  ApplicationRef,
  APP_INITIALIZER,
  ErrorHandler,
  NgModule,
} from '@angular/core';
import {
  BrowserModule,
  HAMMER_GESTURE_CONFIG,
  HammerModule,
} from '@angular/platform-browser';
import {
  HttpClient,
  HttpClientModule,
  HTTP_INTERCEPTORS,
} from '@angular/common/http';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { ServiceWorkerModule } from '@angular/service-worker';

import {
  MsalGuard,
  MsalModule,
  MsalRedirectComponent,
} from '@azure/msal-angular';
import { InteractionType, PublicClientApplication } from '@azure/msal-browser';
import {
  NgcCookieConsentModule,
  NgcCookieConsentConfig,
} from 'ngx-cookieconsent';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { AngularMaterialModule } from './shared/angular-material.module';
import { HeaderComponent } from './core/header/header.component';
import { msalConfig, protectedResources } from './auth-config';
import { environment } from '../environments/environment';
import {
  API_BASE_URL,
  APP_BASE_URL,
  APP_INSIGHTS,
} from './shared/app-constants';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { ShareModule } from 'ngx-sharebuttons';
import { ErrorCatchingInterceptor } from './core/error-interceptor.service';
import { AppFooterComponent } from './core/footer/footer.component';
import { NotificationsComponent } from './core/notifications/notifications.component';
import { NotificationComponent } from './core/notifications/notification/notification.component';
import { SharedModule } from './shared/shared.module';
import { AskToLoginComponent } from './core/ask-to-login-dialog/ask-to-login.component';
import { ErrorHandlerService } from './core/error-handler.service';
import {
  LyHammerGestureConfig,
  LY_THEME,
  LY_THEME_NAME,
  StyleRenderer,
  LyTheme2,
} from '@alyle/ui';
import { MinimaLight } from '@alyle/ui/themes/minima';
import { ErrorsComponent } from './core/errors-page/errors-page.component';
import { LoginRedirectComponent } from './login-redirect/login-redirect.component';
import { MsalComponent } from './msal.component';
import { PrivacyComponent } from './public/terms-and-conditions/privacy.component';
import { TermsComponent } from './public/terms-and-conditions/terms.component';
import { map, Observable, of, tap } from 'rxjs';
import { IStringResponse } from './shared/api/string-response';

const appInsightFactory = (): string => {
  return !!environment.appInsights
    ? environment.appInsights.instrumentationKey
    : '';
};

const cookieConfig: NgcCookieConsentConfig = {
  cookie: {
    domain: new URL(window.location.href).host.split(':')[0],
    name: 'cookieconsent_status',
    path: '/',
    expiryDays: 365,
  },
  palette: {
    popup: {
      background: '#000',
    },
    button: {
      background: '#ff3787',
    },
  },
  theme: 'classic',
  type: 'info',
};

@NgModule({
  declarations: [
    AppComponent,
    HeaderComponent,
    AppFooterComponent,
    NotificationsComponent,
    NotificationComponent,
    AskToLoginComponent,
    ErrorsComponent,
    LoginRedirectComponent,
    TermsComponent,
    PrivacyComponent,
  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    BrowserAnimationsModule,
    AngularMaterialModule,
    HttpClientModule,
    MsalModule.forRoot(
      new PublicClientApplication(msalConfig),
      {
        // The routing guard configuration.
        interactionType: InteractionType.Redirect,
        authRequest: {
          scopes: protectedResources.api.scopes,
        },
      },
      {
        // MSAL interceptor configuration.
        // The protected resource mapping maps your web API with the corresponding app scopes. If your code needs to call another web API, add the URI mapping here.
        interactionType: InteractionType.Redirect,
        protectedResourceMap: new Map([
          [protectedResources.api.endpoint, protectedResources.api.scopes],
        ]),
      },
    ),
    FormsModule,
    ReactiveFormsModule,
    ShareModule.withConfig({
      autoSetMeta: true,
    }),
    SharedModule,
    ServiceWorkerModule.register('ngsw-worker.js', {
      enabled: environment.enableServiceWorker,
      // Register the ServiceWorker as soon as the application is stable
      // or after 30 seconds (whichever comes first).
      registrationStrategy: 'registerWhenStable:30000',
    }),
    HammerModule,
    NgcCookieConsentModule.forRoot(cookieConfig),
  ],
  providers: [
    {
      provide: APP_INITIALIZER,
      useFactory: initializeAppFactory,
      deps: [HttpClient],
      multi: true,
    },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: ErrorCatchingInterceptor,
      multi: true,
    },
    // { provide: ErrorHandler, useClass: ErrorHandlerService },
    { provide: API_BASE_URL, useValue: environment.apiBaseUrl },
    { provide: APP_BASE_URL, useValue: environment.appBaseUrl },
    { provide: APP_INSIGHTS, useFactory: appInsightFactory },
    MsalGuard,
    { provide: HAMMER_GESTURE_CONFIG, useClass: LyHammerGestureConfig },
    StyleRenderer,
    LyTheme2,
    { provide: LY_THEME_NAME, useValue: 'minima-light' },
    { provide: LY_THEME, useClass: MinimaLight, multi: true },
  ],
  bootstrap: [AppComponent, MsalRedirectComponent],
})
export class AppModule {
  ngDoBootstrap(ref: ApplicationRef) {
    if (window !== window.parent && !window.opener) {
      ref.bootstrap(MsalComponent);
    } else {
      ref.bootstrap(AppComponent);
    }
  }
}

function initializeAppFactory(httpClient: HttpClient): () => Observable<any> {
  return () => {
    const existingSession = sessionStorage.getItem('ff_user_session_id');
    if (!!existingSession) {
      return of(existingSession);
    }

    const endpointUrl = `${environment.apiBaseUrl}/sessions`;

    return httpClient.post<IStringResponse>(endpointUrl, {}).pipe(
      map((id: IStringResponse) => id.field),
      tap((id: string) => sessionStorage.setItem('ff_user_session_id', id)),
    );
  };
}
