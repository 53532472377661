import { Injectable } from '@angular/core';
import { Observable, Subject } from 'rxjs';
import tinycolor from 'tinycolor2';

export interface Color {
  name: string;
  hex: string;
  darkContrast: boolean;
}

@Injectable({
  providedIn: 'root',
})
export class ThemeService {
  public primaryColor: string = '#161616';
  public accentColor: string = '#ff3787';
  public primaryColorPalette: Color[] = [];
  public accentColorPalette: Color[] = [];

  public darkContrast: Observable<boolean>;
  private _darkContrast = new Subject<boolean>();

  constructor() {
    this.darkContrast = this._darkContrast.asObservable();

    this.savePrimaryColor(this.primaryColor);
    this.saveAccentColor(this.accentColor);
  }

  public savePrimaryColor(primaryColor: string): void {
    this.primaryColor = primaryColor;
    this.primaryColorPalette = computeColors(primaryColor);
    updateTheme(this.primaryColorPalette, 'primary');

    const primaryColorFromPalette = this.primaryColorPalette[5];
    this._darkContrast.next(primaryColorFromPalette.darkContrast);
  }

  public saveAccentColor(accentColor: string): void {
    this.accentColor = accentColor;
    this.accentColorPalette = computeColors(accentColor);
    updateTheme(this.accentColorPalette, 'accent');
  }
}

const updateTheme = (colors: Color[], theme: string): void => {
  colors.forEach((color) => {
    document.documentElement.style.setProperty(
      `--theme-${theme}-${color.name}`,
      color.hex,
    );
    document.documentElement.style.setProperty(
      `--theme-${theme}-contrast-${color.name}`,
      color.darkContrast ? 'rgba(0, 0, 0, 0.87)' : 'white',
    );
  });
};

const computeColors = (hex: string): Color[] => {
  return [
    getColorObject(tinycolor(hex).lighten(52), '50'),
    getColorObject(tinycolor(hex).lighten(37), '100'),
    getColorObject(tinycolor(hex).lighten(26), '200'),
    getColorObject(tinycolor(hex).lighten(12), '300'),
    getColorObject(tinycolor(hex).lighten(6), '400'),
    getColorObject(tinycolor(hex), '500'),
    getColorObject(tinycolor(hex).darken(6), '600'),
    getColorObject(tinycolor(hex).darken(12), '700'),
    getColorObject(tinycolor(hex).darken(18), '800'),
    getColorObject(tinycolor(hex).darken(24), '900'),
    getColorObject(tinycolor(hex).lighten(50).saturate(30), 'A100'),
    getColorObject(tinycolor(hex).lighten(30).saturate(30), 'A200'),
    getColorObject(tinycolor(hex).lighten(10).saturate(15), 'A400'),
    getColorObject(tinycolor(hex).lighten(5).saturate(5), 'A700'),
  ];
};

const getColorObject = (value: tinycolor.Instance, name: string): Color => {
  const c = tinycolor(value);
  return {
    name: name,
    hex: c.toHexString(),
    darkContrast: c.isLight(),
  };
};
