<ul class="image-grid" *ngIf="!reorderMode">
  <li
    *ngFor="let item of allItems"
    class="sponsor"
    (click)="toggleImageSelection(item)"
    [class.selected]="isSelected(item)"
  >
    <img [src]="item.image" [alt]="item.alt" />
    <div class="alt-container">
      {{ item.alt }}
    </div>

    <div class="checked">
      <mat-icon>check_circle</mat-icon>
    </div>
  </li>
</ul>

<div
  class="image-grid drag-drop-container"
  cdkDropList
  cdkDropListOrientation="horizontal"
  (cdkDropListDropped)="onReorderDrop($event)"
  *ngIf="reorderMode"
>
  <div class="sponsor drag-drop-item" *ngFor="let item of reorderArray" cdkDrag>
    <img [src]="item.image" [alt]="item.alt" />
    <div class="alt-container">
      {{ item.alt }}
    </div>
  </div>
</div>
<button
  mat-flat-button
  color="accent"
  class="mt-2"
  (click)="onSubmitReorder()"
  *ngIf="reorderMode"
>
  Submit
</button>
