<div class="discussion-card" *ngIf="discussion">
  <div class="content">{{ discussion.resourceTitle }}</div>

  <div class="footer grid-3">
    <div class="info-container">
      <div class="info-title">Members</div>
      <div class="info">{{ discussion.numberOfMembers ?? 0 }}</div>
    </div>
    <div class="info-container">
      <div class="info-title">Replies</div>
      <div class="info">{{ discussion.numberOfReplies ?? 0 }}</div>
    </div>
    <div class="info-container">
      <div class="info-title">Activity</div>
      <!-- highlight when new reply, need to know when to highligh -->
      <div class="info">{{ timeAgo }}</div>
    </div>
    <div class="info-container mt-3">
      <div class="info-title">Questions</div>
      <div class="info">{{ discussion.numberOfPosts }}</div>
    </div>
    <div class="info-container mt-3">
      <div class="info-title">Views</div>
      <div class="info">{{ discussion.viewedByPeople }}</div>
    </div>
  </div>
</div>

<div class="discussion-card empty" *ngIf="!discussion">
  <emojis name="cry" size="32"></emojis>
  <div class="no-discussions">No {{ feature }} discussions yet.</div>

  <div class="view-all">View all discussion</div>
</div>
