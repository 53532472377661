import { HttpClient } from '@angular/common/http';
import { Inject, Injectable } from '@angular/core';

import { Observable } from 'rxjs';
import { IStringResponse } from '../../shared/api/string-response';
import { API_BASE_URL } from '../../shared/app-constants';
import {
  IFAQ,
  IFAQCategory,
  IFAQPost,
  IFAQUpdate,
  IFAQReorder,
  IFAQCategoryReorder,
} from '../../shared/types/faq.type';

@Injectable({
  providedIn: 'root',
})
export class FrequentlyAskedQuestionsApiService {
  public faq!: IFAQ[];

  constructor(
    @Inject(API_BASE_URL) public apiBaseUrl: string,
    private httpClient: HttpClient,
  ) {}

  public getAll(categoryId: string): Observable<IFAQ[]> {
    const url = `${this.apiBaseUrl}/faq`;

    return this.httpClient.get<IFAQ[]>(url, {
      params: {
        categoryId: categoryId,
      },
    });
  }

  public getAllCategories(): Observable<IFAQCategory[]> {
    const url = `${this.apiBaseUrl}/faq-categories`;

    return this.httpClient.get<IFAQCategory[]>(url);
  }

  public createCategory(name: string): Observable<IStringResponse> {
    const url = `${this.apiBaseUrl}/faq-categories`;

    return this.httpClient.post<IStringResponse>(url, {
      name,
    });
  }

  public updateCategory(
    categoryId: string,
    newName: string,
  ): Observable<IStringResponse> {
    const url = `${this.apiBaseUrl}/faq-categories/${categoryId}`;

    return this.httpClient.patch<IStringResponse>(url, {
      name: newName,
    });
  }

  public removeCategory(categoryId: string) {
    const url = `${this.apiBaseUrl}/faq-categories/${categoryId}`;

    return this.httpClient.delete(url, {
      headers: {
        // accept: '*/*',
        'content-type': 'application/json',
        // 'content-length': '2',
      },
    });
  }

  public reorderCategories(
    faq: IFAQCategoryReorder,
  ): Observable<IStringResponse> {
    const url = `${this.apiBaseUrl}/faq-categories/reorder`;

    return this.httpClient.post<IStringResponse>(url, faq);
  }

  public postFaq(faq: IFAQPost): Observable<IStringResponse> {
    const url = `${this.apiBaseUrl}/faq`;
    return this.httpClient.post<IStringResponse>(url, faq);
  }

  public update(id: string, faq: IFAQUpdate): Observable<IStringResponse> {
    const url = `${this.apiBaseUrl}/faq/${id}`;
    return this.httpClient.patch<IStringResponse>(url, faq);
  }

  public reorder(faq: IFAQReorder): Observable<IStringResponse> {
    const url = `${this.apiBaseUrl}/faq/reorder`;
    return this.httpClient.post<IStringResponse>(url, faq);
  }

  public delete(id: string): Observable<IStringResponse> {
    const url = `${this.apiBaseUrl}/faq/${id}`;
    return this.httpClient.delete<IStringResponse>(url);
  }
}
