import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Inject, Injectable } from '@angular/core';

import { Observable } from 'rxjs';
import { FFInsightsService } from '../core/logging.service';
import { IStringResponse } from '../shared/api/string-response';
import { API_BASE_URL } from '../shared/app-constants';
import {
  IAccessTokenRequest,
  IAccountPost,
  IChangePasswordPost,
  ITokenResponse,
} from './account';

@Injectable({
  providedIn: 'root',
})
export class AccountApiService {
  constructor(
    @Inject(API_BASE_URL) private apiBaseUrl: string,
    private httpClient: HttpClient,
    private ffInsightsService: FFInsightsService,
  ) {}

  public createAccount(user: IAccountPost): Observable<IStringResponse> {
    const url = `${this.apiBaseUrl}/accounts`;
    this.ffInsightsService.logEvent('[Info] Account created with body', {
      firstName: user.firstName,
      lastName: user.lastName,
      email: user.email,
    });
    return this.httpClient.post<IStringResponse>(url, user);
  }

  public requestToken(
    userDetails: IAccessTokenRequest,
  ): Observable<ITokenResponse> {
    const url = `${this.apiBaseUrl}/accounts/token`;
    return this.httpClient.post<ITokenResponse>(url, userDetails);
  }

  public refreshToken(refreshToken: string): Observable<ITokenResponse> {
    const url = `${this.apiBaseUrl}/accounts/refresh-token`;
    return this.httpClient.post<ITokenResponse>(url, { refreshToken });
  }

  public changePassword(
    userId: string,
    update: IChangePasswordPost,
  ): Observable<void> {
    const url = `${this.apiBaseUrl}/accounts/${userId}/change-password`;
    return this.httpClient.post<void>(url, update);
  }

  public confirmAccount(id: string, confirmationId: string): Observable<void> {
    const url = `${this.apiBaseUrl}/accounts/${id}/account-email-confirmation?confirmationId=${confirmationId}`;
    return this.httpClient.post<void>(url, {});
  }

  public requestForgotPassword(email: string): Observable<void> {
    const url = `${this.apiBaseUrl}/accounts/forgot-password`;
    return this.httpClient.post<void>(url, { email });
  }

  public resetPassword(
    id: string,
    token: string,
    newPassword: string,
  ): Observable<void> {
    const headers = new HttpHeaders().set('Authorization', `Bearer ${token}`);

    const url = `${this.apiBaseUrl}/accounts/${id}/reset-password`;
    return this.httpClient.post<void>(url, { newPassword }, { headers });
  }
}
