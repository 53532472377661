import { Component, OnInit } from '@angular/core';
import { Subject, takeUntil } from 'rxjs';
import { ThemeService } from '../theme.service';

@Component({
  selector: 'app-footer',
  templateUrl: 'footer.component.html',
  styleUrls: ['footer.component.scss'],
})
export class AppFooterComponent implements OnInit {
  public isDarkContrast: boolean = false;

  private _destroying = new Subject<void>();

  constructor(private themeService: ThemeService) {}

  public ngOnInit(): void {
    this.themeService.darkContrast
      .pipe(takeUntil(this._destroying))
      .subscribe(
        (isDarkContrast: boolean) => (this.isDarkContrast = isDarkContrast),
      );
  }

  public ngOnDestroy(): void {
    this._destroying.next();
    this._destroying.complete();
  }

  public navigateToLink(url: string): void {
    window.open(url, '_blank');
  }
}
