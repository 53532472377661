<div class="ff-date-range-calendar__shared">
  <div
    class="range-calendar-field d-flex align-items-center"
    #menuTrigger="matMenuTrigger"
    [matMenuTriggerFor]="intervalPick"
  >
    <div class="d-flex align-items-center">
      <mat-icon class="label me-2">calendar_month</mat-icon>
      <span *ngIf="!selectedInterval.custom">{{ selectedInterval.title }}</span>
      <span *ngIf="selectedInterval.custom">
        <ng-container *ngIf="startsAfterUtc">
          <span>{{ startsAfterUtc | date }}</span>
          <span *ngIf="startsBeforeUtc"> - {{ startsBeforeUtc | date }}</span>
        </ng-container>
        <ng-container *ngIf="!startsAfterUtc">
          {{ selectedInterval.title }}
        </ng-container>
      </span>
    </div>
  </div>
</div>

<mat-menu
  [xPosition]="'before'"
  #intervalPick="matMenu"
  class="ff-date-range-calendar__shared-menu"
>
  <div class="d-block d-sm-flex">
    <div class="time-intervals my-2">
      <ng-container *ngFor="let interval of intervals">
        <div
          class="filter-label"
          [class.selected]="interval.id === selectedInterval.id"
          *ngIf="!interval.custom"
          (click)="selectInterval(interval)"
        >
          {{ interval.title }}
        </div>
        <div
          *ngIf="interval.custom"
          class="filter-label"
          [class.selected]="interval.id === selectedInterval.id"
          (click)="toggleAnyDateCalendar($event, interval)"
        >
          {{ interval.title }}
        </div>
      </ng-container>
    </div>
    <mat-divider *ngIf="isAnyDateShown" [vertical]="true"></mat-divider>
    <div
      class="range-calendar my-2"
      *ngIf="isAnyDateShown"
      (click)="$event.stopPropagation()"
    >
      <range-calendar
        [startsAfterUtc]="startsAfterUtc"
        [startsBeforeUtc]="startsBeforeUtc"
        (interval)="selectedChange($event)"
      ></range-calendar>
      <mat-divider></mat-divider>
      <div
        class="d-flex justify-content-between mt-3 px-3 calendar-cta-container"
      >
        <button
          mat-flat-button
          class="ff-base-button"
          (click)="menuTrigger.closeMenu()"
        >
          Cancel
        </button>
        <button
          mat-flat-button
          color="accent"
          (click)="onApplyCustomDates(menuTrigger)"
        >
          Apply
        </button>
      </div>
    </div>
  </div>
</mat-menu>
