<div class="my-4">
  <div class="my-4">
    <span class="mat-body-2 my-2">Categories</span>
    <div class="mt-3">
      <label-list
        [labelList]="categories"
        allLabel="All categories"
        [resetToAllLabelIfAllItemsSelected]="false"
        [selectedLabelsIds]="selectedCategoriesIds"
        (labelChange)="onCategoryChange($event)"
      ></label-list>
    </div>
  </div>
  <div class="mt-96px mb-48px">
    <span class="mat-body-2 my-2">Topics</span>
    <div class="mt-3">
      <label-list
        [labelList]="topics"
        allLabel="All topics"
        [resetToAllLabelIfAllItemsSelected]="false"
        [selectedLabelsIds]="selectedTopicsIds"
        (labelChange)="onTopicChange($event)"
      ></label-list>
    </div>
  </div>
</div>
