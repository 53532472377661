import { Directive, ElementRef, HostListener } from '@angular/core';

@Directive({
  selector: 'mat-tab-group',
})
export class MatTabGroupScrollDirective {
  public get labelContainer() {
    return this.elementRef.nativeElement.querySelector(
      '.mat-tab-label-container',
    );
  }

  public get tabList() {
    return this.elementRef.nativeElement.querySelector('.mat-tab-list');
  }

  public get activeTab() {
    return this.elementRef.nativeElement.querySelector('.mat-tab-label-active');
  }

  /**
   * Get translateX and convert it to scrollLeft
   */
  @HostListener('selectedTabChange')
  public selectedTabChange() {
    const matTabList = this.tabList;
    const style = matTabList?.getAttribute('style');
    if (!style) {
      return;
    }

    // transform: translateX(-63px) => 63
    const translateX = style.match(/\(-(.+)px\)/)?.[1];

    const matTabContainer = this.labelContainer;
    if (matTabContainer && translateX) {
      matTabContainer.scrollLeft = +translateX;
    }

    this.removeDisabledButtonClass();
  }

  @HostListener('click', ['$event'])
  public click(event: any) {
    const target = this.getButtonTarget(event.target);
    if (!target) {
      return;
    }

    if (target.classList.contains('mat-tab-header-pagination-after')) {
      this.scrollRight();
      return;
    }

    this.scrollLeft();
  }

  constructor(public elementRef: ElementRef) {
    setTimeout(() => {
      this.scrollToActiveTab();
    }, 100);
  }

  public getButtonTarget(target: HTMLElement): HTMLElement | void {
    if (target.classList.contains('mat-tab-header-pagination-chevron')) {
      return target.parentElement!;
    }

    if (target.classList.contains('mat-tab-header-pagination')) {
      return target;
    }
  }

  public scrollRight() {
    const labelContainer = this.labelContainer;
    labelContainer.scrollLeft += labelContainer.clientWidth / 2;

    this.removeDisabledButtonClass();
  }

  public scrollLeft() {
    const labelContainer = this.labelContainer;
    labelContainer.scrollLeft -= labelContainer.clientWidth / 2;

    this.removeDisabledButtonClass();
  }

  public scrollToActiveTab() {
    const activeElement = this.activeTab;
    if (!activeElement) {
      return;
    }

    this.labelContainer.scrollLeft = activeElement.offsetLeft;

    this.removeDisabledButtonClass();
  }

  /**
   * Make sure the button is not disabled as we overwrite the scroll position
   */
  public removeDisabledButtonClass() {
    const buttons = this.elementRef.nativeElement.querySelectorAll(
      '.mat-tab-header-pagination-disabled',
    );

    buttons.forEach((button: HTMLElement) => {
      button.classList.remove('mat-tab-header-pagination-disabled');
    });
  }
}
