<div class="ff-rich-text-editor">
  <ng-container *ngIf="readMode; else editMode">
    <div class="readonly" [innerHTML]="text"></div>
  </ng-container>
  <ng-template #editMode>
    <angular-editor *ngIf="showEditor"
      [attr.id]="id"
      [id]="id"
      [formControl]="textForm"
      [config]="editorConfig"
    >
      <ng-template #customButtons let-executeCommandFn="executeCommandFn">
        <ae-toolbar-set *ngIf="allowHeader">
          <button
            class="custom-button"
            mat-icon-button
            (click)="executeCommandForEditor('h1', executeCommandFn)"
          >
            <mat-icon>add</mat-icon>
          </button>
          <button
            class="custom-button"
            mat-icon-button
            (click)="executeCommandForEditor('h5', executeCommandFn)"
          >
            <mat-icon>remove</mat-icon>
          </button>
        </ae-toolbar-set>
      </ng-template>
    </angular-editor>
  </ng-template>
</div>
