<ly-img-cropper
  [config]="config"
  [(scale)]="scale"
  (minScale)="minScale = $event"
  (ready)="setAsReady()"
  (cleaned)="setUnready()"
  (cropped)="onCropped($event)"
  (loaded)="onLoaded($event)"
  (error)="onError($event)"
  [class.cropper-hidden]="!ready"
  [class.complete-hidden]="!imageSelected"
>
</ly-img-cropper>

<div *ngIf="ready" [className]="classes?.['sliderContainer']">
  <ly-slider
    [thumbVisible]="false"
    [min]="minScale"
    [max]="1"
    [(ngModel)]="scale"
    (input)="onSliderInput($event)"
    step="0.000001"
  ></ly-slider>
</div>

<ng-container *ngIf="!cropperImage">
  <input
    #fileInput
    type="file"
    (change)="selectImage($event)"
    accept="image/*"
    hidden
  />

  <button
    *ngIf="buttonStyle === 'flat'"
    mat-flat-button
    color="acent"
    class="mt-2 w-100"
    (click)="fileInput.click()"
  >
    {{ chooseImageLabel }}
  </button>
  <button
    *ngIf="buttonStyle === 'raised'"
    mat-raised-button
    color="acent"
    class="mt-2 w-100"
    (click)="fileInput.click()"
  >
    {{ chooseImageLabel }}
  </button>
</ng-container>

<ng-container *ngIf="cropperImage && !hideCTA">
  <ng-container *ngIf="buttonStyle === 'flat'">
    <button mat-flat-button color="acent" class="mt-2 w-100" (click)="cancel()">
      Cancel
    </button>

    <button
      mat-flat-button
      color="acent"
      class="mt-2 w-100"
      (click)="cropper?.crop()"
    >
      Crop
    </button>
  </ng-container>
  <ng-container *ngIf="buttonStyle === 'raised'">
    <button
      mat-raised-button
      color="acent"
      class="mt-2 w-100"
      (click)="cancel()"
    >
      Cancel
    </button>

    <button
      mat-raised-button
      color="acent"
      class="mt-2 w-100"
      (click)="cropper?.crop()"
    >
      Crop
    </button>
  </ng-container>
</ng-container>
