import { HttpClient } from '@angular/common/http';
import { Inject, Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { IStringResponse } from './shared/api/string-response';
import { API_BASE_URL } from './shared/app-constants';

@Injectable({
  providedIn: 'root',
})
export class SessionApiService {
  constructor(
    @Inject(API_BASE_URL) private apiBaseUrl: string,
    private httpClient: HttpClient,
  ) {}

  public postNewSession(): Observable<IStringResponse> {
    const endpointUrl = `${this.apiBaseUrl}/sessions`;
    return this.httpClient.post<IStringResponse>(endpointUrl, {});
  }
}
