<div class="note-container">
  <div class="note-header">
    <div class="left-section">
      <div (click)="onPlayClicked()">
        <mat-icon>play_arrow</mat-icon>
        <span class="timestamp"
          >Note {{ isNewNote ? timeStamp : note?.timeStamp }}</span
        >
      </div>
    </div>
    <div class="right-section">
      <mat-icon *ngIf="!isNewNote" (click)="onEditClicked()"
        >drive_file_rename_outline</mat-icon
      >
      <mat-icon (click)="onDeleteClicked()">delete_outline</mat-icon>
    </div>
  </div>

  <div class="note-content">
    <ng-container *ngIf="!editMode">
      {{ note?.text }}
    </ng-container>
    <ng-container *ngIf="editMode">
      <textarea
        matInput
        cdkTextareaAutosize
        #autosize
        cdkAutosizeMinRows="1"
        cdkAutosizeMaxRows="5"
        accent="dark"
        [value]="newNoteText"
        #noteTextArea
      ></textarea>
      <button mat-flat-button color="accent" (click)="onEditSubmit()">
        Save
      </button>
    </ng-container>
    <!-- This is my comment established fact that a reader will be distracted by the readable content of a page when looking at its layout. The point of using Lorem Ipsum is that it has a more-or-less normal distribution of letters. -->
  </div>
</div>
