import {
  Component,
  EventEmitter,
  Input,
  OnChanges,
  OnDestroy,
  OnInit,
  Output,
  SimpleChange,
  SimpleChanges,
} from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { combineLatest, concatMap, map, Subject, takeUntil } from 'rxjs';
import { UserAdmin } from 'src/app/user/admin';
import { FileType, MediaApiService } from '../../core/media-api.service';
import { IStringResponse } from '../../shared/api/string-response';
import { EnumHelpers } from '../../shared/enums/helpers';
import { UsersService } from '../../user/users.service';
import { Feature } from '../enums/features';
import { UrlsHelper } from '../helpers';
import { IResource, IResourcePost, ResourceType } from './resource';

@Component({
  selector: 'ff-resources',
  templateUrl: 'resources.component.html',
  styleUrls: ['resources.component.scss'],
})
export class FFResourcesComponent implements OnChanges, OnInit, OnDestroy {
  @Input() public entityId!: string;
  @Input() public feature!: Feature;
  @Input() public canEdit: boolean = true;

  @Input() public largeLabel: boolean = false;

  @Input() public isFeatureAdmin: boolean = false;
  @Input() public resources!: IResource[];

  @Output() public resourceAdded = new EventEmitter<IResourcePost>();
  @Output() public resourceDeleted = new EventEmitter<string>();

  public isAdmin: boolean = false;

  public tools: any[] = [];
  public links: any[] = [];
  public documents: any[] = [];

  public file: File | null = null;

  public featureAsString!: string;

  public addForm = new FormGroup({
    title: new FormControl(null, Validators.required),
    link: new FormControl(null, Validators.required),
    type: new FormControl(ResourceType.Tool, Validators.required),
  });

  public resourceTypes = EnumHelpers.getEnumAsStringArray(ResourceType);

  public isAddFormShown: boolean = false;
  public isDocumentAddition: boolean = false;

  private destroyNotifier = new Subject<void>();

  constructor(
    private usersService: UsersService,
    private mediaApiService: MediaApiService,
  ) {}

  public ngOnChanges(changes: SimpleChanges): void {
    this.separateResourceByType();
    this.isAddFormShown = false;
  }

  public ngOnInit(): void {
    combineLatest([
      this.usersService.isAdminInterfaceShown,
      this.usersService.userAdmin,
    ])
      .pipe(takeUntil(this.destroyNotifier))
      .subscribe(
        ([isAdminInterfaceShown, admin]: [boolean, UserAdmin]) =>
          (this.isAdmin =
            isAdminInterfaceShown &&
            (admin.isSuperAdmin || this.isFeatureAdmin) &&
            this.canEdit),
      );

    this.addForm
      .get('type')!
      .valueChanges.pipe(takeUntil(this.destroyNotifier))
      .subscribe(
        (type: ResourceType) =>
          (this.isDocumentAddition = type === ResourceType.Document),
      );

    this.featureAsString = Feature[this.feature].toLowerCase().slice(0, -1);
    this.separateResourceByType();
  }

  public ngOnDestroy(): void {
    this.destroyNotifier.next();
    this.destroyNotifier.complete();
  }

  public navigateToResource(url: string): void {
    window.open(url, '_blank');
  }

  public showAddForm(): void {
    this.isAddFormShown = true;
  }

  public addDocument(files: FileList | null): void {
    this.file = files![0];
  }

  public submitResource(): void {
    this.addForm.markAllAsTouched();
    this.addForm.updateValueAndValidity();

    if (this.isDocumentAddition) {
      if (
        !this.file ||
        !this.addForm.get('title')!.valid ||
        this.addForm.get('title')!.value.trim() === ''
      ) {
        return;
      }
    } else {
      if (
        !this.addForm.valid ||
        this.addForm.get('title')!.value.trim() === '' ||
        this.addForm.get('link')!.value.trim() === ''
      ) {
        return;
      }
    }

    let resource = null;

    if (this.isDocumentAddition) {
      this.mediaApiService
        .uploadMediaAsync(this.file!, FileType.Document)
        .subscribe((url: IStringResponse) => {
          resource = {
            userId: this.usersService.getUserId(),
            text: this.addForm.get('title')!.value,
            url: url.field,
            type: this.addForm.get('type')!.value,
          };
          this.resourceAdded.emit(resource);
        });
    } else {
      resource = {
        userId: this.usersService.getUserId(),
        text: this.addForm.get('title')!.value,
        url: this.getParsedLink(),
        type: this.addForm.get('type')!.value,
      };
      this.addForm.reset();
      this.resourceAdded.emit(resource);
    }
  }

  public deleteResource(id: string): void {
    this.resourceDeleted.emit(id);

    this.resources = this.resources.filter((d) => d.id !== id);
    this.separateResourceByType();
  }

  private separateResourceByType(): void {
    this.tools = this.resources.filter((r) => r.type === ResourceType.Tool);
    this.links = this.resources.filter((r) => r.type === ResourceType.Link);
    this.documents = this.resources.filter(
      (r) => r.type === ResourceType.Document,
    );
  }

  private getParsedLink(): string {
    return UrlsHelper.parseUrls(this.addForm.get('link')!.value);
  }
}
