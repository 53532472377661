<div class="row mb-4 mx-0" [class.discussion-container]="canHaveChildLevel">
  <div class="col-auto">
    <img
      *ngIf="profilePicUrl"
      class="profile-picture pointer"
      [src]="profilePicUrl"
      alt="profile-picture"
      (click)="onProfilePictureClick()"
    />
  </div>
  <div class="col p-0">
    <div style="width: fit-content" class="p-1">
      <div>
        <span *ngIf="name" class="mat-body-2">{{ name }}</span>
        <span class="mx-2 mat-caption text-gray">{{ createdAt }}</span>
        <span *ngIf="canHaveChildLevel" class="ms-2 mat-caption text-gray"
          >{{ totalAnswers }}
          <span *ngIf="totalAnswers === 1; else answersPlural">answer</span>
          <ng-template #answersPlural>
            <span>answers</span>
          </ng-template>
        </span>
        <span class="ms-2 mat-caption hidden-message" *ngIf="isHidden"
          >HIDDEN</span
        >
        <span
          class="ms-2 mat-caption reported-count-message"
          [class.solved]="!reportedCount || isHidden"
          *ngIf="isAdminShown"
          >REPORTED BY {{ reportedCount }}</span
        >
      </div>
      <div class="my-2 text-container">
        <span class="mat-body-1" [innerText]="discussion.text"></span>
      </div>
    </div>
    <div>
      <div class="d-flex align-items-center text-gray">
        <div class="d-flex align-items-center pointer">
          <mat-icon *ngIf="!hasUserUpvoted" (click)="vote(true)"
            >favorite_outlined</mat-icon
          >
          <mat-icon *ngIf="hasUserUpvoted" (click)="vote(false)" color="accent"
            >favorite</mat-icon
          >

          <div class="ms-2 mat-caption">
            <span [ngPlural]="upvotes">
              <ng-template ngPluralCase="=1">{{ upvotes }} upvote</ng-template>
              <ng-template ngPluralCase="other"
                >{{ upvotes }} upvotes</ng-template
              >
            </span>
          </div>
        </div>

        <div class="ms-4 d-flex align-items-center pointer">
          <button
            *ngIf="isAdminShown || !isReportedByCurrentUser"
            mat-icon-button
            [matMenuTriggerFor]="discussionItemOptions"
          >
            <mat-icon>more_vert</mat-icon>
          </button>
          <mat-icon *ngIf="isReportedByCurrentUser" matTooltip="Reported by you"
            >warning</mat-icon
          >
        </div>
      </div>
    </div>
    <div class="mt-3">
      <div class="mt-2">
        <div class="left-reply-border mt-4 ps-4">
          <ng-container *ngFor="let answer of displayedReplies">
            <discussion
              [isAdminShown]="isAdminShown"
              [reportGroups]="reportGroups"
              [entityId]="entityId"
              [discussion]="answer"
              [canHaveChildLevel]="false"
              [reports]="reports"
            >
            </discussion>
          </ng-container>
          <button
            *ngIf="areMoreRepliesToBeShown"
            mat-button
            color="default"
            class="pointer mb-3 see-more-replies"
            (click)="setMoreVisibleReplies()"
          >
            See more replies ({{
              discussion.discussionItemChildren.totalCount -
                displayedReplies.length
            }})
          </button>
        </div>
      </div>
      <div class="reply-form d-flex mt-2" *ngIf="canHaveChildLevel">
        <div class="avatar-column">
          <img
            *ngIf="user?.profilePictureUrl"
            matPrefix
            class="profile-picture"
            [src]="user?.profilePictureUrl"
            alt="profile-picture"
          />
        </div>
        <div class="col">
          <mat-form-field class="w-100" appearance="standard" color="accent">
            <textarea
              matInput
              cdkTextareaAutosize
              #autosize="cdkTextareaAutosize"
              cdkAutosizeMinRows="1"
              cdkAutosizeMaxRows="100"
              [placeholder]="hasAddedAnswers ? '' : 'Add a reply'"
              (keydown)="onKeyDown($event)"
              (keyup)="onKeyUp($event)"
              (keydown.shift)="isShiftHeld = true"
              (keyup.shift)="isShiftHeld = false"
              (keydown.control)="isShiftHeld = true"
              (keyup.control)="isShiftHeld = false"
              [formControl]="answerForm"
            ></textarea>
            <button
              mat-flat-button
              matSuffix
              [color]="answerForm.dirty ? 'accent' : 'primary'"
              [disabled]="isPostReplyInProgress"
              (click)="submitAnswer()"
            >
              Post
            </button>
          </mat-form-field>
        </div>
      </div>
    </div>
  </div>
</div>

<mat-menu #discussionItemOptions="matMenu">
  <button mat-menu-item (click)="reportItem()">Report</button>
  <button
    *ngIf="isAdminShown && isHidden"
    mat-menu-item
    (click)="hideItem(false)"
  >
    Unhide
  </button>
  <button
    *ngIf="isAdminShown && !isHidden"
    mat-menu-item
    (click)="hideItem(true)"
  >
    Hide
  </button>
</mat-menu>
