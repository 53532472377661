import { Inject, Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Observable } from 'rxjs';
import { API_BASE_URL } from '../../shared/app-constants';
import { IPage } from '../../shared/pagination';
import {
  INotification,
  INotificationFilter,
  NotificationPostPayload,
} from './notification';
import { ApiServiceHelper } from '../../shared/helpers';

@Injectable({
  providedIn: 'root',
})
export class NotificationApiService {
  constructor(
    @Inject(API_BASE_URL) private apiBaseUrl: string,
    private httpClient: HttpClient,
  ) {}

  public getNotifications(
    connectionId: string | null,
    filter: INotificationFilter,
  ): Observable<IPage<INotification>> {
    const url = `${this.apiBaseUrl}/notifications`;

    if (!!connectionId) {
      const headers = new HttpHeaders()
        .set('responseType', 'text/plain')
        .set('accept', 'text/plain')
        .set('X-connectionid', connectionId);
      return this.httpClient.get<IPage<INotification>>(url, {
        params: ApiServiceHelper.getQueryParams(filter),
        headers,
      });
    }

    return this.httpClient.get<IPage<INotification>>(url, {
      params: ApiServiceHelper.getQueryParams(filter),
    });
  }

  public markAsRead(id: string): Observable<void> {
    const url = `${this.apiBaseUrl}/notifications/${id}/mark-notification-read`;
    return this.httpClient.post<void>(url, {});
  }

  public markAllAsRead(): Observable<void> {
    const url = `${this.apiBaseUrl}/notifications/mark-all-notifications-read`;
    return this.httpClient.post<void>(url, {});
  }

  public createNotification(payload: NotificationPostPayload) {
    const url = `${this.apiBaseUrl}/notifications`;
    return this.httpClient.post<void>(url, payload);
  }
}
