import { Injectable } from '@angular/core';
import { DateTime } from 'luxon';
import { concatMap, map, Observable, of, tap } from 'rxjs';
import { environment } from 'src/environments/environment';
import { SessionApiService } from '../session-api.service';
import { SessionService } from '../session.service';
import { IStringResponse } from '../shared/api/string-response';
import { UsersApiService } from '../user/users-api.service';
import { AffiliatesApiService } from './affiliates-api.service';

@Injectable({
  providedIn: 'root',
})
export class AffiliatesCodeService {
  constructor(
    private sessionService: SessionService,
    private affiliatesApiService: AffiliatesApiService,
    private userApiService: UsersApiService,
  ) {}

  public handleNewAffiliateCode(affiliateParam: string): void {
    if (!affiliateParam) {
      return;
    }

    this.sessionService
      .addSessionInStorage()
      .pipe(
        tap((sessionId: string) =>
          this.handleAffiliateCodeChanges(affiliateParam),
        ),
        concatMap((sessionId: string) =>
          this.affiliatesApiService.postAffiliateCLick(
            affiliateParam,
            sessionId,
          ),
        ),
      )
      .subscribe((_) => this.updateAffiliateIdOnUser(affiliateParam));
  }

  private updateAffiliateIdOnUser(affiliateId: string): void {
    const userId = localStorage.getItem('usrid');
    if (!userId) {
      return;
    }
    this.userApiService.updateUserAsync(userId, { affiliateId }).subscribe();
  }

  private handleAffiliateCodeChanges(code: string): void {
    const existingAffiliate = localStorage.getItem('usr_affiliate');
    if (!!existingAffiliate) {
      const existingAffiliateExpireAt = localStorage.getItem(
        'usr_affiliate_expire_utc',
      );

      if (!existingAffiliateExpireAt) {
        localStorage.removeItem('usr_affiliate');
        throw new Error(
          'Affiliate expiration date not found in local storage.',
        );
      }

      if (new Date() < new Date(existingAffiliateExpireAt)) {
        return;
      }
    }

    const newExpiration = DateTime.now()
      .plus(environment.isLocalhost ? { minutes: 2 } : { days: 7 })
      .toJSDate()
      .toISOString();

    localStorage.setItem('usr_affiliate', code);
    localStorage.setItem('usr_affiliate_expire_utc', newExpiration);
  }
}
