import { Injectable } from '@angular/core';
import { map, Observable, withLatestFrom } from 'rxjs';
import { IPlan } from '../plans/plans';
import { BillingService } from '../shared/billing.service';
import { IUserAPI } from '../user/user';
import { UsersService } from '../user/users.service';

@Injectable({
  providedIn: 'root',
})
export class PaymentStatusTextsService {
  public freeTrialDays: Observable<number>;

  constructor(
    private billingSerivce: BillingService,
    private usersService: UsersService,
  ) {
    this.freeTrialDays = this.billingSerivce.getPlans().pipe(
      withLatestFrom(this.usersService.userApiData),
      map(([plans, userData]) => {
        const firstPlan = plans[0];

        if (
          !!(
            !!firstPlan.settings?.freeTrialDays &&
            (!userData ||
              (!!userData && userData.eligibleForFreeTrial) ||
              (!!userData &&
                !!userData.freeTrialUntilUtc &&
                !!userData.upcomingSubscription &&
                new Date(userData.freeTrialUntilUtc) > new Date()))
          )
        ) {
          return firstPlan.settings?.freeTrialDays;
        }
        return 0;
      }),
    );
  }
}
