import { Inject, Injectable } from '@angular/core';

import { MsalService } from '@azure/msal-angular';

import {
  BehaviorSubject,
  concatMap,
  map,
  Observable,
  of,
  Subject,
  tap,
} from 'rxjs';

import {
  IUserAPI,
  IUserPatch,
  IUserPost,
  IUserTokenDetails,
  IUserTokenResponse,
} from './user';
import { UsersApiService } from './users-api.service';

import jwt_decode from 'jwt-decode';
import { UserAdmin } from './admin';
import { IAccountPost } from './account';
import { APP_BASE_URL } from '../shared/app-constants';
import { BillingService } from '../shared/billing.service';
import { DateHelpers } from '../shared/date/date-helpers';
import { CompleteProgressService } from './complete-progress.service';
import { FREE_TRIAL, PAYING } from '../shared/analytic-tags/tags';
import { DataLayerService } from '../core/data-layer.service';
import { FFInsightsService } from '../core/logging.service';

@Injectable({
  providedIn: 'root',
})
export class UsersService {
  public isAdminInterfaceShown: Observable<boolean>;
  private _isAdminInterfaceShown: BehaviorSubject<boolean>;

  public accessToken: Observable<string>;
  private _accessToken = new BehaviorSubject('');

  public userAdmin: Observable<UserAdmin>;
  private _userAdmin = new BehaviorSubject(new UserAdmin([]));

  public authenticationDone: Observable<void>;
  private _authenticationDone = new Subject<void>();

  public userApiData: Observable<IUserAPI | undefined>;
  private _userApiData = new BehaviorSubject<IUserAPI | undefined>(undefined);

  public profilePictureUrl?: string;

  constructor(
    @Inject(APP_BASE_URL) private appBaseUrl: string,
    private authService: MsalService,
    private usersApiService: UsersApiService,
    private dataLayerService: DataLayerService,
    private ffInsightsService: FFInsightsService,
  ) {
    const adminViewPreference = localStorage.getItem('admin_view_preference');
    const isAdminInterfaceShown =
      !!adminViewPreference && adminViewPreference === 'true';
    this._isAdminInterfaceShown = new BehaviorSubject(isAdminInterfaceShown);

    this.isAdminInterfaceShown = this._isAdminInterfaceShown.asObservable();
    this.accessToken = this._accessToken.asObservable();
    this.authenticationDone = this._authenticationDone.asObservable();
    this.userAdmin = this._userAdmin.asObservable();
    this.userApiData = this._userApiData.asObservable();

    if (!!localStorage.getItem('at')) {
      this.extractUserRoles();
      this.fetchUserApiData();
    }
  }

  public setUserLoggedIn(
    wasLoggedIn: boolean,
    internalUser?: Partial<IAccountPost>,
  ): void {
    sessionStorage.removeItem(CompleteProgressService.CACHE_KEY);

    if (wasLoggedIn) {
      this.createUser(internalUser);
    } else {
      this._authenticationDone.next();
    }
  }

  public setAdmin(isAdmin: boolean): void {
    this._isAdminInterfaceShown.next(isAdmin);
  }

  public isAnyTypeOfAdmin(): boolean {
    return !!this._userAdmin.value.isAnyTypeOfAdmin;
  }

  public clearUserDetails(): void {
    localStorage.removeItem('usrid');
    localStorage.removeItem('azAt');
    localStorage.removeItem('noSubscription');
    localStorage.removeItem('at');
    localStorage.removeItem('externalId');
    localStorage.removeItem('internalusr');
    localStorage.removeItem('onetimesession');
    localStorage.removeItem('refreshToken');
    localStorage.removeItem('email');
    sessionStorage.removeItem(CompleteProgressService.CACHE_KEY);
  }

  public getUserId(): string {
    return !!localStorage.getItem('usrid')
      ? localStorage.getItem('usrid')!.toString()
      : '';
  }

  public setUserDataFromStorage(): void {
    this.extractUserRoles();

    this._accessToken.next(localStorage.getItem('at')!.toString());
    this._authenticationDone.next();
  }

  public userHasSubscription(): boolean {
    return (
      !!this._userApiData.value &&
      (!!this._userApiData.value.currentSubscription ||
        !!this._userApiData.value.upcomingSubscription)
    );
  }

  public userDidNotHadSubscription(): boolean {
    return (
      !this.userHasSubscription() &&
      !!this._userApiData.value!.eligibleForFreeTrial
    );
  }

  public userHadSubscription(): boolean {
    return (
      !this.userHasSubscription() &&
      !this._userApiData.value!.eligibleForFreeTrial
    );
  }

  private getAffiliateCode(): string | undefined {
    const existingAffiliate = localStorage.getItem('usr_affiliate');
    if (!existingAffiliate) {
      return undefined;
    }

    const existingAffiliateExpireAt = localStorage.getItem(
      'usr_affiliate_expire_utc',
    );

    if (!existingAffiliateExpireAt) {
      localStorage.removeItem('usr_affiliate');
      throw new Error('Affiliate expiration date not found in local storage.');
    }

    if (new Date() < new Date(existingAffiliateExpireAt)) {
      return existingAffiliate;
    }

    return undefined;
  }

  private createUser(internalUser?: Partial<IAccountPost>): void {
    const affiliateCode = this.getAffiliateCode();
    const subscriptionCode = localStorage.getItem('subscriptionCode');

    this.usersApiService
      .createUserAsync(
        this.getUserPostData(internalUser),
        affiliateCode,
        subscriptionCode ?? undefined,
      )
      .pipe(
        tap((response: IUserTokenResponse) => {
          localStorage.setItem('usrid', response.userId);
          localStorage.setItem('at', response.token);
          this.extractUserRoles();

          this._accessToken.next(response.token);
        }),
        concatMap((response: IUserTokenResponse) =>
          this.usersApiService.getUserAsync(response.userId).pipe(
            map((user: IUserAPI) => ({
              user,
              response,
            })),
          ),
        ),
        tap(({ user, response }) => {
          this._userApiData.next(user);

          const hasSubscription =
            !!user.currentSubscription || !!user.upcomingSubscription;

          if (!hasSubscription) {
            localStorage.setItem('noSubscription', 'true');
          }

          if (!!affiliateCode) {
            localStorage.removeItem('usr_affiliate');
            localStorage.removeItem('usr_affiliate_expire_utc');
          }
          if (!!subscriptionCode) {
            localStorage.removeItem('subscriptionCode');
          }
        }),
      )
      .subscribe(({ user, response }) => {
        const newUser = !response.isExistingUser;
        if (internalUser) {
          const url = newUser ? `${this.appBaseUrl}?newUser=true`: this.appBaseUrl;
          window.open(url, '_self');
          return;
        }

        if (newUser) {
          this.addEventForNewUserInAnalytics(localStorage.getItem('usrid')!);
        }

        this._authenticationDone.next();
      });
  }

  private checkForNewUser(isExistingUser: boolean): void {
    if (isExistingUser) {
      return;
    }

    this.addEventForNewUserInAnalytics(localStorage.getItem('usrid')!);
  }

  private addEventForNewUserInAnalytics(userId: string): void {
    this.dataLayerService.logObject({
      event: 'acquisition.lead',
      acquisition: null,
    });
    this.dataLayerService.logObject({
      event: 'acquisition.lead',
      acquisition: {
        lead: {
          actionField: {
            userId,
          },
        },
      },
    });
  }

  private checkConvertTagForFreeTrialEnd(user: IUserAPI): void {
    if (!user.lastAnalyticsTag && !user.lastMarketingTagAssigned) {
      return;
    }

    if (!!user.lastAnalyticsTag && !!user.lastMarketingTagAssigned) {
      const lastAnalyticsTag = user.lastAnalyticsTag.name;
      const lastMarketingTagAssigned = user.lastMarketingTagAssigned;

      if (
        lastAnalyticsTag === FREE_TRIAL &&
        lastMarketingTagAssigned === PAYING
      ) {
        this.markAnalyticTagAsSent(user);
      }

      return;
    }
  }

  private markAnalyticTagAsSent(user: IUserAPI): void {
    this.usersApiService
      .updateUserAsync(user.id, { sentAnalyticsTag: PAYING })
      .subscribe((_) => {
        this.dataLayerService.logObject({
          event: 'eec.purchase',
          ecommerce: null,
        });
        this.dataLayerService.logObject(this.getGoogleTagObject(user));
      });
  }

  private getGoogleTagObject(userData: IUserAPI): any {
    const subscription =
      userData.currentSubscription || userData.upcomingSubscription;

    if (!subscription) {
      this.ffInsightsService.logEvent(
        '[ERR] Subscription data not found for analytig tag',
      );
      return;
    }

    return {
      event: 'eec.purchase',
      ecommerce: {
        purchase: {
          actionField: {
            id: subscription.id,
            revenue: subscription.plan?.priceUsd,
          },
          products: [
            {
              name: subscription.plan?.title,
              id: subscription.plan?.legacyPlanId,
              price: subscription.plan?.priceUsd,
              quantity: 1,
              coupon: '',
            },
          ],
        },
      },
    };
  }

  public setSessionProfilePic(profilePictureUrl: string): void {
    this._userApiData.next({
      ...this._userApiData.value!,
      profilePictureUrl,
    });

    this.profilePictureUrl = profilePictureUrl;
  }

  public extractUserRoles(): void {
    const token = localStorage.getItem('at')!.toString();
    const decodedToken: IUserTokenDetails = jwt_decode(token);
    const roles: string[] = !!decodedToken.role
      ? ((Array.isArray(decodedToken.role)
          ? decodedToken.role
          : [decodedToken.role]) as string[])
      : [];

    const admin = new UserAdmin(roles);
    this._userAdmin.next(admin);
  }

  public getUserData(): IUserAPI | undefined {
    return this._userApiData.value;
  }

  public setUserData(userData: IUserAPI | undefined): void {
    this._userApiData.next(userData);
  }

  public fetchUserApiData() {
    this.usersApiService
      .getUserAsync(this.getUserId())
      .pipe(
        tap((userData) => {
          if (
            !!userData.currentSubscription ||
            !!userData.upcomingSubscription
          ) {
            localStorage.removeItem('noSubscription');
          }
          this.checkConvertTagForFreeTrialEnd(userData);
        }),
      )
      .subscribe((userData) => {
        this._userApiData.next(userData!);
      });
  }

  public setAutoRenew(autorenew: string | null): void {
    const user = this._userApiData.value;

    if (!user) {
      return;
    }

    user.autoRenewTurnedOffAtUtc = autorenew;

    this._userApiData.next(user);
  }

  public saveUserData(payload: IUserPatch) {
    return this.usersApiService.updateUserAsync(this.getUserId(), payload);
  }

  private getUserPostData(internalUser?: Partial<IAccountPost>): IUserPost {
    if (!internalUser) {
      const user = this.authService.instance.getActiveAccount();
      const username = user!.name?.split(' ');
      const firstName = !!username ? username[0] : undefined;
      const lastName =
        !!username && username.length > 1 ? username[1] : undefined;

      return {
        email: user!.username,
        firstName,
        lastName,
      };
    }

    return {
      email: internalUser.email!,
      firstName: internalUser.firstName,
      lastName: internalUser.lastName,
    };
  }
}
