<section class="app-xxl-container">
  <div class="px-3 px-sm-0">
    <div class="title">{{ entityTitle }}</div>
    <div class="my-2 d-flex align-items-center">
      <star-rating
        [readonly]="true"
        [smallDisplay]="true"
        [initialRating]="
          (entityReviewAverage && totalReviewsCount > 10) || isAdminViewShown
            ? entityReviewAverage
            : 5
        "
      ></star-rating>
      <span class="mx-1">{{
        ((entityReviewAverage && totalReviewsCount > 10) || isAdminViewShown
          ? entityReviewScore
          : 5
        ) | number : '1.1'
      }}</span
      ><span
        *ngIf="totalReviewsCount > 10 || isAdminViewShown"
        class="text-gray"
        >({{ totalReviewsCount }})</span
      >
    </div>
  </div>
  <div class="my-5">
    <div
      *ngIf="!currentUserReview"
      class="d-flex align-items-center px-3 px-sm-0"
    >
      <span class="important-text me-2">Overall rating</span>
      <star-rating
        [initialRating]="rating"
        (onRatingChanged)="changeRating($event)"
      ></star-rating>
    </div>
    <div
      *ngIf="currentUserReview"
      class="d-flex align-items-center align-items-sm-end px-3 px-sm-0"
    >
      <span class="important-text me-2"
        >Reviewed on
        {{ currentUserReview.createdAtUtc | date : 'MM/dd/yyyy' }}</span
      >
      <star-rating
        [readonly]="!showEditField"
        [initialRating]="rating"
        (onRatingChanged)="changeRating($event)"
      ></star-rating>
      <feedback-cta
        class="ms-2"
        [isVerifiedAttendee]="currentUserReview.isUserVerifiedAttendee"
        [usersRating]="rating"
        [emitEvent]="true"
        (ctaClicked)="onEditReview()"
      ></feedback-cta>
    </div>

    <div class="gradient-box my-4" *ngIf="showEditField">
      <div class="review-input">
        <div class="mat-body-2">What did you particulary like?</div>
        <div class="mt-3 mb-5">
          <span
            class="chip me-2 my-1"
            [class.selected]="isLabelSelected(index)"
            *ngFor="let label of feedbackLabels; let index = index"
            (click)="selectLabel(index)"
            >{{ label.title }}</span
          >
        </div>
        <div class="mat-body-2 my-3">Add a written review</div>
        <div>
          <mat-form-field
            class="w-100 mb-3"
            appearance="fill"
            floatLabel="never"
            color="accent"
          >
            <textarea
              #addReviewTextarea
              class="add-review-textarea"
              (input)="onTextareChange()"
              placeholder="What did you like or dislike?"
              matInput
              [(ngModel)]="reviewText"
            ></textarea>
          </mat-form-field>
        </div>
        <div class="mt-4">
          <button
            mat-flat-button
            class="me-1"
            [disabled]="isFormModified()"
            (click)="cancelFeedbackCompletion()"
          >
            Cancel
          </button>
          <button
            color="accent"
            [disabled]="isFormModified()"
            mat-flat-button
            (click)="submitFeedback()"
          >
            <span class="d-flex align-items-center"
              ><span>Submit </span
              ><mat-progress-spinner
                class="ms-2"
                *ngIf="isFeedbackSaving"
                diameter="16"
                mode="indeterminate"
              ></mat-progress-spinner
            ></span>
          </button>
        </div>
      </div>
    </div>
  </div>

  <div class="my-5 px-3 px-sm-0">
    <mat-checkbox
      (change)="changeOnlyVerified($event)"
      [(ngModel)]="onlyVerified"
      >Verified {{ entityType | titlecase }}
      {{ entityType == 'course' ? 'Graduates' : 'Attendees' }}</mat-checkbox
    >
  </div>

  <div class="d-block d-sm-flex flex-gap">
    <div class="my-3 m-sm-0 mx-3 mx-sm-0 feedback-summary-container">
      <div class="d-block d-sm-none title">Rating Statistics</div>
      <mat-divider class="d-block d-sm-none"></mat-divider>
      <div class="reviews">
        <feedback-summary
          [feedbackSummary]="feedbackSummary"
        ></feedback-summary>
      </div>
    </div>

    <section class="review-section justify-content-between">
      <mat-divider></mat-divider>
      <ng-container *ngFor="let review of reviews">
        <div class="mb-4"></div>
        <div class="d-flex align-items-center px-3 px-sm-0">
          <img
            *ngIf="review.userProfilePicture"
            class="profile-picture pointer"
            [src]="review.userProfilePicture"
            alt="profile-picture"
          />
          <span class="ms-2 user-name">{{ review.userName }}</span>

          <button
            class="ms-2"
            (click)="hideFeedback(review.userId, !review.hiddenAtUtc)"
            mat-flat-button
            *ngIf="isAdminViewShown"
            [color]="review.hiddenAtUtc ? 'accent' : 'primary'"
          >
            <span *ngIf="!review.hiddenAtUtc">Hide</span>
            <span *ngIf="review.hiddenAtUtc">Unhide</span>
          </button>
        </div>
        <div class="mt-2 px-3 px-sm-0">
          <div class="d-flex align-items-center mb-3">
            <star-rating
              [readonly]="true"
              [smallDisplay]="true"
              [initialRating]="review.rating"
            ></star-rating>
          </div>
          <div class="text-gray mt-1">
            Reviewed in {{ review.fromCountry }} on
            {{ review.createdAtUtc | date : 'MM/dd/yyyy' }}
          </div>
          <div class="text-accent" *ngIf="review.isUserVerifiedAttendee">
            Verified Event Attendee
          </div>
        </div>
        <div class="d-block d-sm-flex align-items-center px-3 px-sm-0 mt-4">
          <span class="important-text me-3" *ngIf="review.rating >= 3"
            >What did you particularly like?</span
          >
          <span class="important-text me-3" *ngIf="review.rating < 3"
            >What did you not like?</span
          >
          <div>
            <ng-container *ngFor="let label of review.labels">
              <span class="chip m-1">{{ label.title }}</span>
            </ng-container>
          </div>
        </div>
        <div
          *ngIf="review.text && isAdminViewShown"
          class="review-text text-gray px-3 px-sm-0 mt-2"
        >
          {{ review.text }}
        </div>
        <mat-divider class="my-4"></mat-divider>
      </ng-container>
    </section>
  </div>
  <div
    *ngIf="totalReviewsCount - reviews.length > 0"
    class="w-100 d-flex justify-content-center"
  >
    <button
      mat-button
      color="default"
      class="px-4 py-3 pointer show-more-button"
      (click)="loadMoreReviews()"
    >
      See more ({{ totalReviewsCount - reviews.length }})
    </button>
  </div>
</section>
