import { Component, Input, OnInit } from '@angular/core';
import { FormControl } from '@angular/forms';
import { UsersService } from '../../../user/users.service';
import { DiscussionApiService } from '../discussion-api.service';
import { IDiscussionMember, IDiscussionSummary } from '../discussion';
import { MatDialog } from '@angular/material/dialog';
import { DiscussionsMemberDialog } from '../../../discussions/discussions-member-dialog/discussions-member-dialog.component';
import { SocialMediaType } from '../../social-media-api.service';
import { AskToLoginComponent } from '../../../core/ask-to-login-dialog/ask-to-login.component';

@Component({
  selector: 'discussions-members-list',
  templateUrl: './discussions-members-list.component.html',
  styleUrls: ['./discussions-members-list.component.scss'],
})
export class DiscussionsMembersListComponent implements OnInit {
  public isLoggedIn: boolean = false;
  public membersList!: Array<IDiscussionMember>;

  public shownCount: number = 0;
  public pageIndex: number = 0;
  public totalCount: number = 0;

  public socialMediaTypes: SocialMediaType[] = [];

  @Input()
  public entityId!: string;

  public sortOptions = [
    {
      viewValue: 'Most Recent',
      value: 'updatedAtUtc',
      sortingDesc: true,
    },
    {
      viewValue: 'Most Questions',
      value: 'userPosts',
      sortingDesc: true,
    },
    {
      viewValue: 'Most Replies',
      value: 'userReplies',
      sortingDesc: true,
    },
  ];
  public sortBy = new FormControl(this.sortOptions[0]);

  constructor(
    private usersService: UsersService,
    private discussionApiService: DiscussionApiService,
    private dialog: MatDialog,
  ) {}

  public ngOnInit(): void {
    this.isLoggedIn = !!this.usersService.getUserId();

    this.fetchMembers(0);

    this.sortBy.valueChanges.subscribe(() => {
      this.fetchMembers(0);
    });
  }

  public loadMore(): void {
    this.fetchMembers(this.pageIndex + 1);
  }

  public openMemberDialog(member: IDiscussionMember) {
    if (!this.isLoggedIn) {
      this.askUserToSignIn();
      return;
    }

    this.dialog.open(DiscussionsMemberDialog, {
      data: {
        member,
      },
      panelClass: 'member-dialog',
    });
  }

  public askUserToSignIn(): void {
    this.dialog.open(AskToLoginComponent, {
      width: '400px',
    });
  }

  private fetchMembers(pageIndex: number): void {
    const sortOptions = {
      sortBy: this.sortBy.value?.value ?? this.sortOptions[0].value,
      sortDescending:
        this.sortBy.value?.sortingDesc ?? this.sortOptions[0].sortingDesc,
    } as { sortBy: string; sortDescending: boolean };

    this.discussionApiService
      .getDiscussionMembers(this.entityId, {
        ...sortOptions,
        pageIndex,
        pageSize: 10,
      })
      .subscribe((_membersList) => {
        let membersList = this.membersList;
        if (pageIndex === 0) {
          membersList = _membersList.items;
        } else {
          membersList.push(..._membersList.items);
        }

        this.shownCount = membersList.length;
        this.pageIndex = pageIndex;
        this.totalCount = _membersList.totalCount;

        this.membersList = membersList;
      });
  }
}
