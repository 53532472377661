import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class SidebarService {
  private static DEFAULT_COMPONENT_VALUE = { component: undefined, data: {} };

  private _sidebarComponentSubject = new BehaviorSubject<{
    component: any;
    data: Record<string, unknown>;
  }>(SidebarService.DEFAULT_COMPONENT_VALUE);

  public get activeComponentObservable() {
    return this._sidebarComponentSubject.asObservable();
  }

  /**
   * TODO: Consider multiple sidebars in parallel.
   */
  public renderComponent(component: any, data = {}) {
    this._sidebarComponentSubject.next({ component, data });
  }

  public destroyComponent() {
    this._sidebarComponentSubject.next(SidebarService.DEFAULT_COMPONENT_VALUE);
  }
}
