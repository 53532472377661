<h1 mat-dialog-title>Upload image</h1>
<div mat-dialog-content>
  <div *ngIf="croppedImage" class="my-2">
    <button mat-flat-button color="accent" (click)="clearThumbnail()">
      Clear upload
    </button>
  </div>
  <div *ngIf="!croppedImage">
    <image-cropper
      #cropper
      [(cropperImage)]="cropperImage"
      (croppedImage)="onThumbnailSelected($event)"
      [config]="cropperConfig"
      [hideCTA]="true"
    ></image-cropper>
  </div>
  <div *ngIf="croppedImage">
    <img [src]="croppedImage" alt="Event preview" />
  </div>
</div>
<div mat-dialog-actions class="d-flex justify-content-end">
  <button mat-button (click)="onCancel()">Cancel</button>
  <button mat-flat-button color="accent" (click)="onSubmit()" cdkFocusInitial>
    Submit
  </button>
</div>
