<ng-container *ngIf="faq.length > 0 || isAdmin">
  <div class="d-flex align-items-center mb-3">
    <button
      *ngIf="isAdmin"
      color="accent"
      (click)="setAddMode(true)"
      mat-icon-button
    >
      <mat-icon>add</mat-icon>
    </button>
    <button
      *ngIf="isAdmin"
      color="accent"
      (click)="reorderFaq()"
      mat-icon-button
    >
      <mat-icon>reorder</mat-icon>
    </button>
    <span class="section-title">Frequently asked questions</span>
  </div>
  <ng-container *ngIf="(addMode || editMode) && isAdmin">
    <form [formGroup]="faqForm">
      <mat-form-field
        class="w-50"
        appearance="fill"
        floatLabel="never"
        color="accent"
      >
        <mat-label> Question </mat-label>
        <input matInput formControlName="question" />
      </mat-form-field>
      <div>
        <mat-form-field
          class="w-50"
          appearance="fill"
          floatLabel="never"
          color="accent"
        >
          <mat-label> Answer </mat-label>
          <textarea matInput formControlName="answer"></textarea>
        </mat-form-field>
      </div>
      <button
        *ngIf="addMode"
        mat-flat-button
        class="mb-2"
        (click)="submit()"
        color="accent"
      >
        Submit
      </button>
      <button
        *ngIf="editMode"
        mat-flat-button
        class="mb-2"
        (click)="submit(true)"
        color="accent"
      >
        Submit
      </button>
    </form>
  </ng-container>
  <ff-entity-faq-accordion
    [multi]="false"
    [faqs]="faq"
    *ngIf="!isReorder"
    (onEditClicked)="edit($event)"
    (onDeleteClicked)="deleteFaq($event)"
  ></ff-entity-faq-accordion>

  <ng-container *ngIf="isReorder">
    <div cdkDropList class="example-list" (cdkDropListDropped)="drop($event)">
      <div class="example-box" *ngFor="let faqItem of faq" cdkDrag>
        {{ faqItem.question }}
      </div>
    </div>
    <button
      mat-flat-button
      color="accent"
      class="mt-2"
      (click)="submitFaqOrder()"
    >
      Submit
    </button>
  </ng-container>
</ng-container>
