import { HttpParams } from '@angular/common/http';
import { getCurrencySymbol } from '@angular/common';

import { BehaviorSubject } from 'rxjs';

export class ApiServiceHelper {
  public static getQueryParams(object: any): HttpParams {
    for (const key in object) {
      if (object[key] === undefined) {
        delete object[key];
      }
    }
    return new HttpParams({ fromObject: object });
  }
}

export class UrlsHelper {
  public static parseUrls(url: string): string {
    let notParsedLink = url;

    const hasHttpPrefix =
      notParsedLink.indexOf('https://') > -1 ||
      notParsedLink.indexOf('http://') > -1;

    if (notParsedLink.indexOf('https:/') > -1 && !hasHttpPrefix) {
      notParsedLink = notParsedLink.slice(7);
    }

    if (notParsedLink.indexOf('http:/') > -1 && !hasHttpPrefix) {
      notParsedLink = notParsedLink.slice(6);
    }

    if (hasHttpPrefix) {
      return notParsedLink;
    }

    return `https://${notParsedLink}`;
  }
}

export class TimeSpanHelper {
  public static convertToTimeSpan(durationSeconds: number): string {
    const h = Math.floor(durationSeconds / 3600)
        .toString()
        .padStart(2, '0'),
      m = Math.floor((durationSeconds % 3600) / 60)
        .toString()
        .padStart(2, '0'),
      s = Math.floor(durationSeconds % 60)
        .toString()
        .padStart(2, '0');

    const timeSpan = `${h}:${m}:${s}`;
    return timeSpan;
  }

  public static convertFromTimeSpanToSeconds(timeSpan: string): number {
    let tokens = timeSpan.split(':');
    const h = +tokens[0];
    const m = +tokens[1];
    const s = +tokens[2];

    return h * 3600 + m * 60 + s;
  }
}

export class StringHelpers {
  public static getDashSeparatedString(title: string): string {
    const parsedTitle = title
      .replace('&', 'and')
      .replace(/[^a-zA-Z0-9 ]/g, '');

    return parsedTitle
      .split(' ')
      .filter((part) => part !== '')
      .join('-');
  }

  /**
   * Removed everything till the end of the search string
   * EX: RemoveMatchAndBeforeIt('the fox jumped over the lazy dog', 'jumped ') => 'over the lazy dog'
   */
  public static RemoveMatchAndBeforeIt(target: string, search: string): string {
    const matchIndex = target.lastIndexOf(search);
    if (matchIndex === -1) {
      return target;
    }

    return target.substring(matchIndex + search.length, target.length);
  }
}

export class PinMenuHelpers {
  public static moveMatHeaderToTop(
    isDestroyedSubject: BehaviorSubject<boolean>,
    classes?: string,
  ): void {
    // Very tiny timeout to make sure angular managed to do the change detection
    setTimeout(() => {
      if (isDestroyedSubject.value) {
        return;
      }

      PinMenuHelpers.moveTabHeaderBackToBottom();

      const header = document.querySelector('.app-header');
      const matHeader = document.querySelector('.mat-tab-header');

      if (!header || !matHeader) {
        return;
      }

      if (classes) {
        matHeader.classList.add(classes);
      }

      header.appendChild(matHeader);
    }, 100);
  }

  public static moveTabHeaderBackToBottom() {
    const matHeader = document.querySelector('.app-header .mat-tab-header');

    if (!matHeader) {
      return;
    }

    document.querySelector('.pin-menu')?.prepend(matHeader);
  }

  public static destroyTopHeader() {
    const matHeader = document.querySelector('.app-header .mat-tab-header');

    if (!matHeader) {
      return;
    }

    matHeader.remove();
  }
}

export class CurrencyHelper {
  public static getSymbolFromCurrencyIsoCode(isoCode: string): string {
    return getCurrencySymbol(isoCode, 'narrow');
  }
}
