<div class="mat-caption ms-2">{{ label }}</div>
<button
  color="accent"
  #menuTrigger
  class="border-radius"
  mat-flat-button
  [matMenuTriggerFor]="menu"
>
  {{ openDatePickerLabel }}
</button>
<mat-menu #menu="matMenu">
  <div (click)="$event.stopPropagation()">
    <div>
      <div class="date-picker-component">
        <mat-calendar
          [startAt]="date"
          [(selected)]="date"
          (yearSelected)="onYearChangedInYearView($event)"
          (monthSelected)="onMonthChangedInMonthView($event)"
          class="date-selector"
        ></mat-calendar>
      </div>
      <mat-form-field class="ms-3 w-90px" color="accent" appearance="standard">
        <input [formControl]="timeFormControl" type="time" matInput />
      </mat-form-field>
      <div class="mat-caption ms-3">HH:mm</div>
      <div class="d-flex justify-content-end px-2">
        <button color="accent" mat-flat-button (click)="onSubmit()">
          Submit
        </button>
      </div>
    </div>
  </div>
</mat-menu>
