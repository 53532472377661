import { HttpClient } from '@angular/common/http';
import { Inject, Injectable } from '@angular/core';
import { Observable, of } from 'rxjs';
import { INote } from 'src/app/shared/models/notes';
import { IStringResponse } from '../../shared/api/string-response';
import { API_BASE_URL } from '../../shared/app-constants';
import { ApiServiceHelper } from '../../shared/helpers';
import { IPatchUserEvent, IUserEvent } from '../../events/event';
import { IPostFeedback } from 'src/app/shared/feedback/feedback';

// Hack required to push the sidebar on top level, there are alternatives but they take time to implementat
// As we discussed with @Mark, the sidebar might move next to the video
@Injectable({ providedIn: 'root' })
export class UserEventApiService {
  constructor(
    @Inject(API_BASE_URL) private apiBaseUrl: string,
    private httpClient: HttpClient,
  ) {}

  public getUserEvents(
    eventId: string,
    userId?: string,
  ): Observable<IUserEvent> {
    const url = `${this.apiBaseUrl}/user-events/${eventId}`;
    return this.httpClient.get<IUserEvent>(url, {
      params: ApiServiceHelper.getQueryParams({ userId }),
    });
  }

  public patchUserEvents(
    updates: IPatchUserEvent,
  ): Observable<IStringResponse> {
    const url = `${this.apiBaseUrl}/user-events/${updates.eventId}`;
    return this.httpClient.patch<IStringResponse>(url, { ...updates });
  }

  public postUserFeedback(
    eventId: string,
    feedback: IPostFeedback,
  ): Observable<IStringResponse> {
    const url = `${this.apiBaseUrl}/user-events/${eventId}/feedback`;
    return this.httpClient.post<IStringResponse>(url, feedback);
  }

  public patchUserFeedback(
    eventId: string,
    userId: string,
    feedback: Partial<IPostFeedback>,
  ): Observable<IStringResponse> {
    const url = `${this.apiBaseUrl}/user-events/${eventId}/feedback/${userId}`;
    return this.httpClient.patch<IStringResponse>(url, feedback);
  }

  public deleteUserNote(eventId: string, noteId: number) {
    const url = `${this.apiBaseUrl}/user-events/${eventId}/notes/${noteId}`;
    return this.httpClient.delete<IStringResponse>(url);
  }

  public updateUserNote(eventId: string, noteId: number, newText: string) {
    const url = `${this.apiBaseUrl}/user-events/${eventId}/notes/${noteId}`;
    return this.httpClient.patch<IStringResponse>(url, {
      text: newText,
      // TODO: clarify later
      screenshotUrl: 'test',
    });
  }

  public createUserNote(eventId: string, note: Partial<INote>) {
    const url = `${this.apiBaseUrl}/user-events/${eventId}/notes`;
    return this.httpClient.post<IStringResponse>(url, {
      text: note.text,
      timeStamp: note.timeStamp,
    });
  }

  public getUsersPointsForEvents(): Observable<[number, number]> {
    return of([6, 8]);
  }
}
