<mat-form-field class="w-100" appearance="fill" floatLabel="always">
  <mat-label>{{ label }}</mat-label>
  <mat-chip-list #chipList aria-label="Topics">
    <mat-chip *ngFor="let chip of chips" (removed)="remove(chip)">
      {{ chip.title }}
      <button matChipRemove>
        <mat-icon>cancel</mat-icon>
      </button>
    </mat-chip>
    <input
      *ngIf="showSuggestions"
      [placeholder]="placeholder"
      #chipsInput
      [formControl]="chipsForm"
      [matAutocomplete]="auto"
      [matChipInputFor]="chipList"
    />
    <input
      *ngIf="!showSuggestions"
      [placeholder]="placeholder"
      #chipsInput
      [formControl]="chipsForm"
      [matChipInputFor]="chipList"
      [matChipInputSeparatorKeyCodes]="separatorKeysCodes"
      (matChipInputTokenEnd)="onNewChip($event)"
    />
  </mat-chip-list>
  <mat-autocomplete #auto="matAutocomplete" (optionSelected)="selected($event)">
    <mat-option
      *ngFor="let chip of filteredChips | async | slice: 0:10"
      [value]="chip"
    >
      {{ chip.title }}
    </mat-option>
  </mat-autocomplete>
</mat-form-field>
