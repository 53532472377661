import { Injectable } from '@angular/core';
import { combineLatest, map, Observable } from 'rxjs';
import { UsersService } from 'src/app/user/users.service';
import { INavigationItem } from './navigation';

@Injectable({
  providedIn: 'root',
})
export class NavigationService {
  constructor(private usersService: UsersService) {}

  public getNavigationItems(): Observable<INavigationItem[]> {
    return combineLatest([
      this.usersService.isAdminInterfaceShown,
      this.usersService.userAdmin,
      this.usersService.userApiData,
    ]).pipe(
      map(([isAdminInterfaceShown, admin, userData]) => {
        let navigationItems = NAVIGATION_ITEMS;

        if (isAdminInterfaceShown && admin.isAnyTypeOfAdmin) {
          return navigationItems;
        }

        const isLoggedIn = this.usersService.getUserId();

        return navigationItems.filter((navigationItem) => {
          let isItemShown = true;

          if (navigationItem.isAuthenticationNeeded) {
            isItemShown = !!isLoggedIn;
          }

          if (navigationItem.onlyForPayingUsers) {
            isItemShown = isItemShown && (!!userData?.currentSubscription || !!userData?.upcomingSubscription);
          }

          isItemShown = isItemShown && !navigationItem.isAdminNeeded;
          return isItemShown;
        });
      }),
    );
  }
}

export const NAVIGATION_ITEMS: INavigationItem[] = [
  {
    name: 'Admin',
    routerLink: 'admin',
    isAdminNeeded: true,
    isAuthenticationNeeded: false,
  },
  {
    name: 'Dashboard',
    routerLink: 'dashboard',
    isAdminNeeded: false,
    isAuthenticationNeeded: true,
  },
  {
    name: 'Start Here',
    routerLink: undefined,
    externalUrl: 'https://join.futurefemales.co/ffbs_platform',
    isAdminNeeded: false,
    isAuthenticationNeeded: true,
    goesInMoreMenu: false,
    onlyForPayingUsers: true,
  },
  {
    name: 'Courses',
    routerLink: 'courses',
    isAdminNeeded: false,
    isAuthenticationNeeded: false,
  },
  {
    name: 'Events',
    routerLink: 'events',
    isAdminNeeded: false,
    isAuthenticationNeeded: false,
  },
  {
    name: 'Discussions',
    routerLink: 'discussions',
    isAdminNeeded: false,
    isAuthenticationNeeded: false,
    goesInMoreMenu: true,
  },
  {
    name: 'Resources',
    routerLink: undefined,
    externalUrl: 'https://join.futurefemales.co/platform_resources',
    isAdminNeeded: false,
    isAuthenticationNeeded: true,
    onlyForPayingUsers: true,
    goesInMoreMenu: true,
  },
  {
    name: 'FAQ',
    routerLink: 'faq',
    isAdminNeeded: false,
    isAuthenticationNeeded: false,
    goesInMoreMenu: true,
  },
  {
    name: 'Plans',
    routerLink: 'plans',
    isAdminNeeded: false,
    isAuthenticationNeeded: false,
    goesInMoreMenu: true,
  },
];
