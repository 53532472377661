<div class="shared-discussion-root">
  <div class="members-list">
    <discussions-members-list [entityId]="entityId"></discussions-members-list>
  </div>
  <div class="discussions-container">
    <div class="mat-body-1 d-flex shared-discussions">
      <div class="avatar-column">
        <img
          *ngIf="profilePicUrl"
          matPrefix
          class="profile-picture"
          [src]="profilePicUrl"
          alt="profile-picture"
        />
      </div>
      <div class="col">
        <mat-form-field
          class="w-100 add-question-field"
          appearance="fill"
          floatLabel="never"
          color="accent"
        >
          <textarea
            matInput
            cdkTextareaAutosize
            #autosize="cdkTextareaAutosize"
            cdkAutosizeMinRows="1"
            cdkAutosizeMaxRows="100"
            placeholder="Add a question or comment"
            (keydown)="onKeyDown($event)"
            (keyup)="onKeyUp($event)"
            (keydown.shift)="isShiftHeld = true"
            (keyup.shift)="isShiftHeld = false"
            (keydown.control)="isShiftHeld = true"
            (keyup.control)="isShiftHeld = false"
            [formControl]="questionForm"
          ></textarea>
          <button
            mat-flat-button
            matSuffix
            [color]="questionForm.dirty ? 'accent' : 'primary'"
            [disabled]="isQuestionPostInProgress"
            (click)="submitQuestion()"
          >
            Post
          </button>
        </mat-form-field>
      </div>
    </div>
    <div class="mt-3">
      <ng-container *ngFor="let discussion of discussionItems">
        <discussion *ngIf="isAdminShown"
          [isAdminShown]="true"
          [reports]="reportedIds"
          [discussion]="discussion"
          [entityId]="entityId"
          [canHaveChildLevel]="true"
          [reportGroups]="reportGroups"
          (answerAdded)="onAnswerAdded()"
        ></discussion>
        <discussion *ngIf="!isAdminShown"
          [isAdminShown]="false"
          [reports]="reportedIds"
          [discussion]="discussion"
          [entityId]="entityId"
          [canHaveChildLevel]="true"
          (answerAdded)="onAnswerAdded()"
        ></discussion>
      </ng-container>
      <div *ngIf="areMoreQuestions" class="text-center">
        <button
          mat-button
          color="default"
          (click)="loadMoreQuestions()"
          class="px-4 py-3 pointer show-more-button"
        >
          See more ({{ totalItemsShown }})
        </button>
      </div>
    </div>
  </div>
</div>
