import { Injectable } from '@angular/core';
import { Observable, Subject } from 'rxjs';
import { ICourseReadModel } from '../../courses/courses';

export interface ICourseInteraction {
  listTitle: string;
  course: ICourseReadModel | null;
  interaction: ListInteraction;
}

export enum ListInteraction {
  UserAddedOnFavorite = 'UserAddedOnFavorite',
  UserRemovedFromFavorite = 'UserRemovedFromFavorite',
}

@Injectable({
  providedIn: 'root',
})
export class CourseListService {
  public newInteraction: Observable<ICourseInteraction>;
  private _newInteraction: Subject<ICourseInteraction>;

  constructor() {
    this._newInteraction = new Subject();
    this.newInteraction = this._newInteraction.asObservable();
  }

  public sendNewInteraction(interaction: ICourseInteraction): void {
    this._newInteraction.next(interaction);
  }
}
