<div class="sticky-top app-header" color="primary">
  <div class="d-flex justify-content-between align-items-center w-100">
    <div class="d-none d-lg-flex align-items-center ms-4 my-2">
      <div class="d-flex align-items-center">
        <img
          *ngIf="!isDarkContrast"
          class="app-logo pointer p-2"
          [routerLink]="'/'"
          src="/assets/images/ff-logo-dark.png"
          alt="Future females logo"
        />
        <img
          *ngIf="isDarkContrast"
          class="app-logo pointer p-2"
          [routerLink]="'/'"
          src="/assets/images/ff-logo-light.png"
          alt="Future females logo"
        />
      </div>
      <div class="d-none d-xl-inline">
        <ng-container *ngFor="let item of navigationItems | async">
          <a
            class="ms-3 mat-caption pointer navigation-link"
            [routerLink]="item.routerLink"
            (click)="openInNewTab(item.externalUrl)"
          >
            <b
              class="navigation-text"
              [class.selected]="
                selectedTab.toLowerCase() === item.routerLink?.toLowerCase()
              "
              >{{ item.name }}</b
            ></a
          >
        </ng-container>
      </div>
      <div class="d-none d-lg-inline d-xl-none">
        <ng-container *ngIf="midDeviceNavigationItems | async as midNavItems">
          <ng-container *ngFor="let mainItem of midNavItems.main">
            <a
              class="ms-3 mat-caption pointer navigation-link"
              [routerLink]="mainItem.routerLink"
              (click)="openInNewTab(mainItem.externalUrl)"
            >
              <b
                class="w-100 navigation-text"
                [class.selected]="
                  selectedTab.toLowerCase() ===
                  mainItem.routerLink?.toLowerCase()
                "
                >{{ mainItem.name }}</b
              ></a
            >
          </ng-container>
          <span class="ms-3 pointer" [mat-menu-trigger-for]="moreOptions">
            <b class="w-100 navigation-text" [class.selected]="moreOptionActive"
              >More</b
            >
          </span>
          <mat-menu #moreOptions="matMenu">
            <ng-container *ngFor="let mainItem of midNavItems.moreOptions">
              <div mat-menu-item class="w-100 my-2">
                <a
                  class="w-100 mat-caption pointer navigation-link"
                  [routerLink]="mainItem.routerLink"
                  (click)="openInNewTab(mainItem.externalUrl)"
                >
                  <b
                    class="navigation-text more-options"
                    [class.selected]="
                      selectedTab.toLowerCase() ===
                      mainItem.routerLink?.toLowerCase()
                    "
                    >{{ mainItem.name }}</b
                  ></a
                >
              </div>
            </ng-container>
          </mat-menu>
        </ng-container>
      </div>
    </div>
    <div
      class="d-flex d-lg-none justify-content-start align-items-center ms-4 my-2"
    >
      <img
        *ngIf="!isDarkContrast"
        class="app-logo pointer my-1"
        [routerLink]="'/'"
        src="/assets/images/FF-logo-square-dark.png"
        alt="Future females logo"
      />
      <img
        *ngIf="isDarkContrast"
        class="app-logo pointer my-1"
        [routerLink]="'/'"
        src="/assets/images/FF-logo-square-light.png"
        alt="Future females logo"
      />
      <div class="ms-3 d-inline d-lg-none">
        <div
          class="pointer d-flex align-items-center flex-nowrap"
          (click)="isMobilenavOpen = !isMobilenavOpen"
        >
          <mat-icon *ngIf="!isMobilenavOpen">arrow_drop_down</mat-icon>
          <mat-icon *ngIf="isMobilenavOpen">arrow_drop_up</mat-icon>
          <span class="navigation-text-mobile">{{ selectedTab }}</span>
        </div>
      </div>
    </div>

    <ng-container>
      <div *ngIf="isUserLoggedIn" class="notifications-container">
        <button mat-icon-button (click)="onNotificationClicked()">
          <mat-icon
            *ngIf="notificationCount === 0"
            svgIcon="notifications_outlined"
          ></mat-icon>
          <mat-icon
            *ngIf="notificationCount > 0 && notificationCount < 10"
            [matBadge]="notificationCount"
            matBadgeColor="accent"
            svgIcon="notifications_outlined"
          ></mat-icon>
          <mat-icon
            *ngIf="notificationCount > 9"
            matBadge="9+"
            matBadgeColor="accent"
            svgIcon="notifications_outlined"
          ></mat-icon>
        </button>
        <!-- <button
          class="me-1"
          *ngIf="isUserAdmin"
          mat-icon-button
          [matMenuTriggerFor]="theme"
        >
          <mat-icon>format_color_fill</mat-icon>
        </button> -->
        <img
          [matMenuTriggerFor]="profileMenu"
          *ngIf="profilePic && profilePic !== ''"
          class="mx-2 profile-picture pointer"
          [src]="profilePic + '?rand=' + timestamp"
          alt="profile-picture"
        />
      </div>
      <div *ngIf="!isUserLoggedIn">
        <button
          class="d-block d-sm-none me-1"
          [matMenuTriggerFor]="mobileLoginButtons"
          mat-icon-button
        >
          <mat-icon>account_circle</mat-icon>
        </button>
        <div class="d-none d-sm-inline-block">
          <button
            mat-button
            color="default"
            [routerLink]="'/sign-in'"
            class="me-3"
          >
            Sign in
          </button>
          <button
            mat-flat-button
            color="accent"
            [routerLink]="'/sign-up'"
            class="me-2"
          >
            Join
          </button>
        </div>
      </div>
    </ng-container>
  </div>
</div>

<mat-menu #mobileLoginButtons="matMenu">
  <button mat-menu-item [routerLink]="'/sign-in'">Sign in</button>
  <button mat-menu-item [routerLink]="'/sign-up'">Join</button>
</mat-menu>
<mat-menu #profileMenu="matMenu">
  <div class="header-menu">
    <ng-container *ngIf="name">
      <div class="user-info">
        <div class="left">
          <div class="avatar">
            <img [src]="profilePic" alt="profile picture" />
          </div>
          <div class="full-name">
            {{ name }}
          </div>
        </div>
      </div>
      <mat-divider class="my-2"></mat-divider>
    </ng-container>

    <ng-container *ngIf="!profileCompleteCard?.isCompleted">
      <div class="progress-container">
        <div class="left">
          <span class="mat-subheading-1"
            >Complete your profile ({{
              profileCompleteCard?.completedPercentage
            }}%)</span
          >

          <div class="mt-3">
            <mat-progress-bar
              color="accent"
              class="bg-contrast"
              [value]="profileCompleteCard?.completedPercentage"
            >
            </mat-progress-bar>
          </div>
        </div>
        <div class="right">
          <a routerLink="/profile/profile" class="pointer">
            <mat-icon>arrow_forward</mat-icon>
          </a>
        </div>
      </div>

      <mat-divider class="my-2"></mat-divider>
    </ng-container>
    <div class="action-buttons-container">
      <menu mat-menu-item *ngIf="userHasAdminRole" class="my-0">
        <mat-slide-toggle class="me-4" [formControl]="isAdminForm">
          <span class="mat-subheading-1">Admin</span>
        </mat-slide-toggle>
      </menu>
      <menu mat-menu-item class="my-0" (click)="navigate('/profile')">
        <span>
          <mat-icon fontSet="material-icons-outlined">account_circle</mat-icon>
          <span class="mat-subheading-1 m-0">My profile</span>
        </span>
      </menu>
      <menu
        mat-menu-item
        class="my-0"
        (click)="navigate('/profile/account-settings')"
      >
        <span>
          <mat-icon fontSet="material-icons-outlined">settings</mat-icon>
          <span class="mat-subheading-1 m-0">Account settings</span>
        </span>
      </menu>
      <menu
        mat-menu-item
        class="my-0"
        (click)="navigate('/profile/membership-billing')"
      >
        <span>
          <mat-icon fontSet="material-icons-outlined">verified</mat-icon>
          <span class="mat-subheading-1 m-0">Membership & billing</span>
        </span>
      </menu>
      <!-- <menu mat-menu-item [matMenuTriggerFor]="themes" class="my-0">
        <span>
          <mat-icon fontSet="material-icons-outlined">light_mode</mat-icon>
          <span class="mat-subheading-1 m-0">Theme</span>
        </span>
      </menu> -->
      <menu mat-menu-item (click)="logout()" class="my-0">
        <span>
          <mat-icon>logout</mat-icon>
          <span class="mat-subheading-1 m-0">Logout</span>
        </span>
      </menu>
      <mat-menu #themes="matMenu">
        <button mat-menu-item>Dark</button>
        <button mat-menu-item>Light</button>
      </mat-menu>
    </div>
  </div>
</mat-menu>

<mat-menu #theme="matMenu">
  <div class="p-3" (click)="$event.stopPropagation()">
    <mat-form-field>
      <input matInput type="text" [(ngModel)]="primaryColor" />
    </mat-form-field>
    <button mat-raised-button color="primary" (click)="savePrimaryColor()">
      Save Primary Color
    </button>
    <mat-form-field>
      <input matInput type="text" [(ngModel)]="accentColor" />
    </mat-form-field>
    <button mat-raised-button color="accent" (click)="saveAccentColor()">
      Save Accent Color
    </button>
  </div>
</mat-menu>

<div
  *ngIf="isMobilenavOpen"
  (click)="isMobilenavOpen = !isMobilenavOpen"
  class="d-inline d-xl-none px-4 pt-4"
  style="
    position: fixed;
    top: 64px;
    background-color: var(--theme-primary-500);
    width: 100%;
    height: 100%;
    z-index: 9999;
  "
>
  <ng-container *ngFor="let item of navigationItems | async">
    <div
      [routerLink]="item.routerLink"
      [class.selected]="
        selectedTab.toLowerCase() === item.routerLink?.toLowerCase()
      "
      class="py-4 w-100 navigation-text-item-mobile pointer"
      (click)="openInNewTab(item.externalUrl)"
    >
      {{ item.name }}
    </div>
  </ng-container>
</div>
