import { Injectable } from '@angular/core';
import { MatSnackBar } from '@angular/material/snack-bar';

@Injectable({
  providedIn: 'root',
})
export class NotifyService {
  constructor(private snackBar: MatSnackBar) {}

  public throwError(message: string, duration = 3000): void {
    this.openSnackBar(message, 'error', duration);
  }

  public throwWarning(message: string, duration = 3000): void {
    this.openSnackBar(message, 'warn', duration);
  }

  public showInfo(message: string, duration = 3000): void {
    this.openSnackBar(message, 'info', duration);
  }

  private openSnackBar(
    message: string,
    type: 'warn' | 'error' | 'info',
    duration: number,
  ): void {
    this.snackBar.open(message, 'Close', {
      horizontalPosition: 'end',
      verticalPosition: 'bottom',
      duration,
      panelClass: `notification-service-snackbar-${type}`,
    });
  }
}
