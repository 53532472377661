<div *ngIf="uploadProgress && uploadAzureProgress">
  <ng-container
    *ngIf="
      uploadProgress.status === UploadStatus.Failed ||
      uploadAzureProgress.status === UploadStatus.Failed ||
      uploadAzureProgress.captionStatus === UploadStatus.Failed
    "
  >
    <div *ngIf="uploadAzureProgress.status === UploadStatus.Failed">
      Video upload was not successful.
    </div>
    <div *ngIf="uploadAzureProgress.captionStatus === UploadStatus.Failed">
      Caption upload was not successful.
    </div>
  </ng-container>
  <ng-container
    *ngIf="
      uploadProgress.status === UploadStatus.Done &&
      uploadAzureProgress.status === UploadStatus.Done &&
      !!videoUrl
    "
  >
    <ng-container
      *ngIf="
        uploadAzureProgress.captionStatus === UploadStatus.InProgress ||
        uploadAzureProgress.captionStatus === UploadStatus.NotStarted
      "
    >
      Captions processing:
      {{ uploadAzureProgress.captionsUploadProgressPercentage }}%
    </ng-container>
    <ng-container *ngIf="videoUrl && captionUrl">
      <div>Has video with captions</div>
    </ng-container>
  </ng-container>
  <div
    *ngIf="
      (uploadProgress.status === UploadStatus.Started ||
        uploadProgress.status === UploadStatus.InProgress) &&
      !(
        uploadAzureProgress.status === UploadStatus.Started ||
        uploadAzureProgress.status === UploadStatus.InProgress
      )
    "
  >
    <div class="d-flex align-items-center">
      <div class="me-2">File upload:</div>
      <div class="w-30px">{{ uploadProgress.uploadProgressPercentage }}%</div>
    </div>
  </div>
  <div
    *ngIf="
      (uploadAzureProgress.status === UploadStatus.Started ||
        uploadAzureProgress.status === UploadStatus.InProgress) &&
      uploadProgress.status === UploadStatus.Done
    "
  >
    <div
      *ngIf="uploadAzureProgress.diskFileId"
      class="d-flex align-items-center"
    >
      <div class="me-2">Video processing:</div>
      <div class="w-30px">
        {{ uploadAzureProgress.uploadProgressPercentage }}%
        <ng-container
          *ngIf="
            uploadAzureProgress.uploadProgressPercentage > 79 &&
            uploadAzureProgress.uploadProgressPercentage < 90
          "
          > (Thumbnail generating)</ng-container
        >
        <ng-container
          *ngIf="
            uploadAzureProgress.uploadProgressPercentage > 90 &&
            uploadAzureProgress.uploadProgressPercentage < 99
          "
          > (Wrapping everything up)</ng-container
        >
      </div>
    </div>
  </div>
</div>
