<h1 mat-dialog-title>Hi!</h1>
<div mat-dialog-content>
  <div class="d-flex justify-content-center w-100">
    <mat-progress-spinner
      color="accent"
      diameter="32"
      *ngIf="!freeTrialDays && freeTrialDays !== 0"
      mode="indeterminate"
    ></mat-progress-spinner>
  </div>
  <p *ngIf="freeTrialDays === 0">
    You are trying to access a members only feature
  </p>
  <p *ngIf="freeTrialDays && freeTrialDays !== 0">
    You are trying to access a members only feature. Grab your free
    {{ freeTrialDays }} day free trial by clicking 'JOIN' below
  </p>
</div>
<div mat-dialog-actions class="d-flex justify-content-end">
  <button mat-button color="default" (click)="login('sign-in')" class="me-3">
    Sign in
  </button>
  <button
    mat-flat-button
    color="accent"
    (click)="login('sign-up')"
    class="me-2"
  >
    Join
  </button>
</div>
