import { Injectable } from '@angular/core';
import { Router, CanActivate } from '@angular/router';
import { combineLatest, map, Observable } from 'rxjs';
import { UserAdmin } from '../user/admin';
import { UsersService } from '../user/users.service';

@Injectable({
  providedIn: 'root',
})
export class AdminGuardService implements CanActivate {
  constructor(public usersService: UsersService, public router: Router) {}

  canActivate(): Observable<boolean> {
    return combineLatest([
      this.usersService.isAdminInterfaceShown,
      this.usersService.userAdmin,
    ]).pipe(
      map(([isAdminInterfaceShown, admin]: [boolean, UserAdmin]) => {
        const canAccess = isAdminInterfaceShown && admin.isAnyTypeOfAdmin;
        if (canAccess) {
          return true;
        } else {
          this.router.navigate(['']);
          return false
        }
      }),
    );
  }
}
