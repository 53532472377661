<div
  class="d-flex p-3"
  [class.not-read]="!isRead"
  [class.read]="isRead"
  (click)="navigateToResource()"
>
  <div *ngIf="notification.senderPictureUrl" class="me-3">
    <img class="profile-picture" [src]="notification.senderPictureUrl" />
  </div>
  <div>
    <div class="mat-body-1">{{ notification.text }}</div>
    <div *ngIf="createdAt" class="mat-caption text-grey">
      <span>{{ createdAt }}</span>
    </div>
  </div>
</div>
