<mat-radio-group
  aria-labelledby="location-radio-group"
  class="radio-group"
  (change)="onChange($event)"
  [(ngModel)]="selectedLocation"
>
  <mat-radio-button
    class="radio-button"
    *ngFor="let location of locationOptions"
    [value]="location"
  >
    {{ location.title }}
    <span *ngIf="location.value.usedCount"
      >({{ location.value.usedCount }})</span
    >
    <span *ngIf="!location.value.usedCount"
      >(0)</span
    >
  </mat-radio-button>
</mat-radio-group>
