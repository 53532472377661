<div>
  <div class="resources-label" [class.large]="largeLabel">Resources</div>
</div>
<div class="resources-card-container">
  <div>
    <ng-container *ngIf="isAdmin">
      <div class="mb-3">
        <button
          class="no-shadow"
          mat-icon-button
          color="accent"
          matTooltip="Add new resource"
          (click)="showAddForm()"
        >
          <mat-icon>add</mat-icon>
        </button>
      </div>
    </ng-container>
    <div>
      <div *ngIf="isAddFormShown">
        <form [formGroup]="addForm">
          <div class="mb-3">
            <mat-form-field
              color="accent"
              class="me-2"
              appearance="fill"
              floatLabel="never"
            >
              <mat-label>Type</mat-label>
              <mat-select required formControlName="type">
                <mat-option
                  *ngFor="let type of resourceTypes"
                  [value]="type.view"
                  >{{ type.viewValue }}</mat-option
                >
              </mat-select>
            </mat-form-field>
          </div>
          <mat-form-field appearance="fill" floatLabel="never" color="accent">
            <mat-label> Title </mat-label>
            <input matInput formControlName="title" />
          </mat-form-field>
          <mat-form-field
            *ngIf="!isDocumentAddition"
            class="ms-2 w-50"
            appearance="fill"
            floatLabel="never"
            color="accent"
          >
            <mat-label> Link </mat-label>
            <input matInput formControlName="link" />
          </mat-form-field>
          <ng-container *ngIf="isDocumentAddition">
            <button
              mat-flat-button
              class="ms-2"
              color="accent"
              (click)="fileInput.click()"
            >
              Upload document
            </button>
            <span *ngIf="file" class="ms-2">{{ file.name }}</span>
            <input
              type="file"
              #fileInput
              class="d-none"
              (change)="addDocument(fileInput.files)"
            />
          </ng-container>
          <div class="mt-3">
            <button mat-flat-button color="accent" (click)="submitResource()">
              Submit
            </button>
          </div>
        </form>
      </div>

      <div class="row resource-columns-container w-100 mt-3">
        <div class="col-md-4 resource-column">
          <div class="d-flex align-items-center">
            <mat-icon [svgIcon]="'tools'">tools</mat-icon>
            <span class="ms-2 column-label">Tools</span>
          </div>

          <ul class="mt-4">
            <ng-container *ngIf="tools.length > 0; else noTools">
              <li class="pointer mat-body-1" *ngFor="let tool of tools">
                <a
                  class="link"
                  href="javascript:void(0);"
                  (click)="navigateToResource(tool.url)"
                  >{{ tool.text }}</a
                >
                <button
                  *ngIf="isAdmin"
                  mat-icon-button
                  (click)="deleteResource(tool.id)"
                >
                  <mat-icon>delete</mat-icon>
                </button>
                <mat-icon *ngIf="!isAdmin" class="link-icon"
                  >open_in_new</mat-icon
                >
              </li>
            </ng-container>
            <ng-template #noTools>
              <li class="pointer mat-body-1">
                No tools for this {{ featureAsString }}
              </li>
            </ng-template>
          </ul>
        </div>
        <div class="col-md-4 resource-column">
          <div class="d-flex align-items-center">
            <mat-icon [svgIcon]="'links'">links</mat-icon>
            <span class="ms-2 column-label">Links</span>
          </div>

          <ul class="mt-4">
            <ng-container *ngIf="links.length > 0; else noLinks">
              <li *ngFor="let link of links">
                <a
                  class="link pointer mat-body-1"
                  href="javascript:void(0);"
                  (click)="navigateToResource(link.url)"
                  >{{ link.text }}</a
                >
                <button
                  *ngIf="isAdmin"
                  mat-icon-button
                  (click)="deleteResource(link.id)"
                >
                  <mat-icon>delete</mat-icon>
                </button>
              </li>
            </ng-container>
            <ng-template #noLinks>
              <li class="mat-body-1">
                No links for this {{ featureAsString }}
              </li>
            </ng-template>
          </ul>
        </div>
        <div class="col-md-4 resource-column last">
          <div class="d-flex align-items-center">
            <mat-icon [svgIcon]="'documents'">documents</mat-icon>
            <span class="ms-2 column-label">Documents</span>
          </div>

          <ul class="mt-4">
            <ng-container *ngIf="documents.length > 0; else noDocuments">
              <li *ngFor="let document of documents">
                <a
                  class="link pointer mat-body-1"
                  href="javascript:void(0);"
                  (click)="navigateToResource(document.url)"
                  >{{ document.text }}</a
                >
                <button
                  *ngIf="isAdmin"
                  mat-icon-button
                  (click)="deleteResource(document.id)"
                >
                  <mat-icon>delete</mat-icon>
                </button>
              </li>
            </ng-container>
            <ng-template #noDocuments>
              <li class="mat-body-1">
                No documents for this {{ featureAsString }}
              </li>
            </ng-template>
          </ul>
        </div>
      </div>
    </div>
  </div>
</div>
