import { HttpClient } from '@angular/common/http';
import { Inject, Injectable } from '@angular/core';
import { API_BASE_URL } from './app-constants';

export interface SocialMediaType {
  name: string;
  socialMediaType: number;
  url: string;
}

@Injectable({ providedIn: 'root' })
export class SocialMediaAPIService {
  public static iconsClass = {
    Facebook: 'bi bi-facebook',
    Instagram: 'bi bi-instagram',
    LinkedIn: 'bi bi-linkedin',
    TikTok: 'bi bi-tiktok',
    YouTube: 'bi bi-youtube',
    GitHub: 'bi bi-github',
    Twitter: 'bi bi-twitter',
    WhatsApp: 'bi bi-whatsapp',
  } as Record<string, string>;

  constructor(
    @Inject(API_BASE_URL) private apiBaseUrl: string,
    private httpClient: HttpClient,
  ) {}

  public getSocialMediaTypes() {
    return this.httpClient.get<SocialMediaType[]>(
      `${this.apiBaseUrl}/socialmedia`,
    );
  }

  public patchSocialMediaTypes(
    socialMediaId: string,
    type: number,
    username: string,
  ) {
    return this.httpClient.patch<SocialMediaType[]>(
      `${this.apiBaseUrl}/socialmedia/${socialMediaId}/user`,
      {
        socialMediaType: type,
        username,
      },
    );
  }

  public postSocialMediaTypes(type: number, username: string) {
    return this.httpClient.post<SocialMediaType[]>(
      `${this.apiBaseUrl}/socialmedia/user`,
      {
        socialMediaType: type,
        username,
      },
    );
  }

  public deleteSocialMediaTypes(type: number) {
    return this.httpClient.delete<SocialMediaType[]>(
      `${this.apiBaseUrl}/socialmedia/user`,
      {
        params: {
          SocialMediaType: type,
        },
      },
    );
  }

  public postOrPatchSocialMediaTypes(
    type: number,
    username: string,
    socialMediaId?: string,
  ) {
    if (socialMediaId && !username) {
      return this.deleteSocialMediaTypes(type);
    }

    if (socialMediaId) {
      return this.patchSocialMediaTypes(socialMediaId, type, username);
    }

    return this.postSocialMediaTypes(type, username);
  }
}
