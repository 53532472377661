import {
  Component,
  EventEmitter,
  Input,
  OnChanges,
  OnDestroy,
  OnInit,
  Output,
  SimpleChanges,
} from '@angular/core';
import { FormControl } from '@angular/forms';
import { Subject, takeUntil } from 'rxjs';
import { IInterval, INTERVALS } from '../../date/intervals';

import { ICityCountry } from '../../models/location';
import { IBaseFilter } from '../filters';
import { IEventFilterResultCount, ILocationOption } from './location-filter/location-filter';

export interface IFieldFilterConfiguration {
  location: boolean;
  date: boolean;
}

@Component({
  selector: 'field-filters',
  templateUrl: 'field-filters.component.html',
  styleUrls: ['field-filters.component.scss'],
})
export class FieldFiltersComponent implements OnInit, OnChanges, OnDestroy {
  @Input() public resultsCounts!: number;
  @Input() public feature!: string;
  @Input() public featurePlural!: string;
  @Input() public cities!: ICityCountry[];
  @Input() public interval!: IInterval;
  @Input() public location!: ILocationOption;
  @Input() public genericLocationResultCount!: IEventFilterResultCount;
  @Input() public configuration: IFieldFilterConfiguration = {
    location: true,
    date: true,
  };
  @Input() public initFilter: Partial<IBaseFilter> = {};
  @Input() public resetDisabled: boolean = true;

  @Output() public partialFilter!: EventEmitter<Partial<IBaseFilter>>;
  @Output() public resetFilter = new EventEmitter<void>();

  public search = new FormControl('');

  public filter: Partial<IBaseFilter> = {};

  private destroyNotifier = new Subject<void>();

  public intervals: IInterval[] = INTERVALS;

  constructor() {
    this.partialFilter = new EventEmitter();
  }

  public ngOnChanges(changes: SimpleChanges): void {
    if (changes['initFilter'] && !changes['initFilter'].firstChange) {
      const change = changes['initFilter'].currentValue;
      this.search.setValue(change.search, { emitEvent: false });
    }
  }

  public ngOnInit(): void {
    this.search.valueChanges
      .pipe(takeUntil(this.destroyNotifier))
      .subscribe((change: string) => {
        this.filter = {
          ...this.filter,
          search: change,
        };
        this.partialFilter.emit(this.filter);
      });
  }

  public ngOnDestroy(): void {
    this.destroyNotifier.next();
    this.destroyNotifier.complete();
  }

  public onDateIntervalChange(change: any): void {
    this.filter = {
      ...this.filter,
      ...change,
      pageIndex: 0,
    };
    this.partialFilter.emit(this.filter);
  }

  public onLocationChange(change: any): void {
    this.filter = {
      ...this.filter,
      ...change,
      pageIndex: 0,
    };
    this.partialFilter.emit(this.filter);
  }

  public onTopicsAndCategoriesChange(topicsAndCategories: {
    topicIds?: string[];
    categoryIds?: string[];
  }): void {
    this.filter = {
      ...this.filter,
      ...topicsAndCategories,
    };
    this.partialFilter.emit(this.filter);
  }

  public clearFilters(): void {
    this.search.setValue('', { emitEvent: false });

    this.filter = {};
    this.resetFilter.emit();
  }
}
