<mat-radio-group
  aria-labelledby="time-interval-radio-group"
  class="radio-group"
  (change)="onChange($event)"
  [(ngModel)]="selectedInterval"
>
  <mat-radio-button
    class="radio-button"
    *ngFor="let i of intervals"
    [value]="i"
  >
    {{ i.title }}
  </mat-radio-button>
</mat-radio-group>
<ng-container *ngIf="selectedInterval.custom">
  <div class="calendar-container">
    <range-calendar
      [startsAfterUtc]="startsAfterUtc"
      [startsBeforeUtc]="startsBeforeUtc"
      (interval)="selectedChange($event)"
    ></range-calendar>
  </div>
</ng-container>
