<h1 mat-dialog-title>Report reason</h1>
<div mat-dialog-content>
  <mat-chip-list selectable multiple>
    <mat-chip
      #reportChips="matChip"
      color="accent"
      *ngFor="let chip of reportTags; let index = index"
      (click)="toggleCategoryChipState(index)"
      ><span class="mat-body-2">{{ chip.title }}</span></mat-chip
    >
  </mat-chip-list>
  <div class="mt-3">
    <mat-form-field class="w-50" appearance="fill" floatLabel="never">
      <mat-label> Other </mat-label>
      <input matInput [formControl]="otherForm" />
    </mat-form-field>
  </div>
  <div
    *ngIf="isSubmitAttempted && selectedTags.length === 0"
    class="mt-3 cross-validation-error-message alert alert-danger"
  >
    No reason selected
  </div>
</div>
<div mat-dialog-actions class="d-flex justofy-content-end">
  <button mat-flat-button (click)="onCancel()">Cancel</button>
  <button mat-flat-button color="accent" cdkFocusInitial (click)="onSubmit()">
    Submit
  </button>
</div>
