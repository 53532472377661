import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Inject, Injectable } from '@angular/core';

import { Observable, of } from 'rxjs';
import { IStringResponse } from '../api/string-response';
import { API_BASE_URL } from '../app-constants';
import { ApiServiceHelper } from '../helpers';
import { DEFAULT_LABEL_FILTER, ILabel, LabelType } from '../label';
import { IPage } from '../pagination';
import {
  IDiscussionFilter,
  IDiscussionItem,
  IDiscussionMember,
  IDiscussionMemberReadModel,
  IDiscussionMembersFilter,
  IDiscussionReport,
  IDiscussionReportPost,
  IDiscussionSummary,
  IDiscussionReportFilter,
  IPostDiscussionItem,
  IUpdateDiscussionItem,
  IDiscussionReportGroup,
} from './discussion';

@Injectable({
  providedIn: 'root',
})
export class DiscussionApiService {
  constructor(
    private httpClient: HttpClient,
    @Inject(API_BASE_URL) private apiBaseUrl: string,
  ) {}

  public getDiscussionsSummary(
    filter: IDiscussionFilter,
  ): Observable<IPage<IDiscussionSummary>> {
    const url = `${this.apiBaseUrl}/discussion`;

    return this.httpClient.get<IPage<IDiscussionSummary>>(url, {
      params: ApiServiceHelper.getQueryParams(filter),
    });
  }

  public getDiscussionItem(
    discussionId: string,
    itemId: string,
  ): Observable<IDiscussionItem> {
    const url = `${this.apiBaseUrl}/discussion/${discussionId}/items/${itemId}`;
    return this.httpClient.get<IDiscussionItem>(url);
  }

  public getDiscussionsSummaryForMemeber(
    id: string,
    memberId: string,
  ): Observable<IDiscussionMemberReadModel> {
    const url = `${this.apiBaseUrl}/discussion/${id}/members/${memberId}`;
    return this.httpClient.get<IDiscussionMemberReadModel>(url);
  }

  public getDiscussionSummary(id: string): Observable<IDiscussionSummary> {
    const url = `${this.apiBaseUrl}/discussion/${id}`;

    return this.httpClient.get<IDiscussionSummary>(url);
  }

  public getDiscussionItems(
    id: string,
    filter: IDiscussionFilter,
  ): Observable<IPage<IDiscussionItem>> {
    const url = `${this.apiBaseUrl}/Discussion/${id}/items`;
    return this.httpClient.get<IPage<IDiscussionItem>>(url, {
      params: ApiServiceHelper.getQueryParams(filter),
    });
  }

  public getDiscussionItemReports(
    filter: IDiscussionReportFilter,
  ): Observable<IPage<IDiscussionReport>> {
    const url = `${this.apiBaseUrl}/discussion-item-reports`;
    return !!localStorage.getItem('usrid')
      ? this.httpClient.get<IPage<IDiscussionReport>>(url, {
          params: ApiServiceHelper.getQueryParams(filter),
        })
      : of({
          totalCount: 0,
          items: [],
        });
  }

  public getDiscussionItemReportGroups(
    filter: any,
  ): Observable<IPage<IDiscussionReportGroup>> {
    const url = `${this.apiBaseUrl}/discussion-item-reports/groups`;
    return !!localStorage.getItem('usrid')
      ? this.httpClient.get<IPage<IDiscussionReportGroup>>(url, {
          params: ApiServiceHelper.getQueryParams(filter),
        })
      : of({
          totalCount: 0,
          items: [],
        });
  }

  public getDiscussionItemReplies(
    id: string,
    itemId: string,
    filter: IDiscussionFilter,
  ): Observable<IPage<IDiscussionItem>> {
    const url = `${this.apiBaseUrl}/Discussion/${id}/items/${itemId}/replies`;
    return this.httpClient.get<IPage<IDiscussionItem>>(url, {
      params: ApiServiceHelper.getQueryParams(filter),
    });
  }

  public patchDiscussionItem(
    id: string,
    itemId: string,
    update: IUpdateDiscussionItem,
  ): Observable<IStringResponse> {
    const url = `${this.apiBaseUrl}/Discussion/${id}/items/${itemId}`;
    return this.httpClient.patch<IStringResponse>(url, update);
  }

  public addDiscussionItemAsync(
    id: string,
    postDiscussionItemRequest: IPostDiscussionItem,
    connectionId?: string,
  ): Observable<IStringResponse> {
    const url = `${this.apiBaseUrl}/Discussion/${id}/items`;

    if (connectionId) {
      const headers = new HttpHeaders().set('X-connectionid', connectionId);
      return this.httpClient.post<IStringResponse>(
        url,
        postDiscussionItemRequest,
        { headers },
      );
    }

    return this.httpClient.post<IStringResponse>(
      url,
      postDiscussionItemRequest,
    );
  }

  public reportDiscussionItem(
    report: IDiscussionReportPost,
  ): Observable<IStringResponse> {
    const url = `${this.apiBaseUrl}/discussion-item-reports`;
    return this.httpClient.post<IStringResponse>(url, report);
  }

  public hideDiscussionItem(
    id: string,
    itemId: string,
    hideComment: boolean,
  ): Observable<IStringResponse> {
    const url = `${this.apiBaseUrl}/discussion/${id}/items/${itemId}/hide`;
    return this.httpClient.post<IStringResponse>(url, { hideComment });
  }

  public getReportTags(): Observable<IPage<ILabel>> {
    const url = `${this.apiBaseUrl}/labels`;
    const filter = {
      ...DEFAULT_LABEL_FILTER,
      labelType: LabelType.CommentReport,
    };

    return this.httpClient.get<IPage<ILabel>>(url, {
      params: ApiServiceHelper.getQueryParams(filter),
    });
  }

  public getDiscussionMembers(
    id: string,
    filter: IDiscussionMembersFilter,
  ): Observable<IPage<IDiscussionMember>> {
    const url = `${this.apiBaseUrl}/discussion/${id}/members`;

    return this.httpClient.get<IPage<IDiscussionMember>>(url, {
      params: ApiServiceHelper.getQueryParams(filter),
    });
  }
}
