<div class="members-list">
  <div class="heading">
    <div class="title mat-caption-1">
      <div>Members</div>
      <mat-form-field
        class="w-100 me-2 my-1"
        appearance="fill"
        floatLabel="never"
        color="accent"
      >
        <mat-select [formControl]="sortBy">
          <mat-option *ngFor="let option of sortOptions" [value]="option">
            {{ option.viewValue }}
          </mat-option>
        </mat-select>
      </mat-form-field>
    </div>
  </div>

  <div class="content">
    <div class="members">
      <div
        *ngFor="let member of membersList"
        class="member"
      >
        <div class="info" (click)="openMemberDialog(member)">
          <img [src]="member.profilePictureUrl" alt="" />
          <div class="name pointer">{{ member.firstName }} {{ member.lastName }}</div>
        </div>
      </div>

      <div
        class="d-flex justify-content-end px-3 mt-2 mat-caption"
        *ngIf="totalCount > shownCount"
      >
        <u class="pointer" (click)="loadMore()"
          >See more ({{ totalCount - shownCount }})</u
        >
      </div>
    </div>
  </div>
</div>
