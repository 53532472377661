import { Injectable } from '@angular/core';
import { Observable, Subject } from 'rxjs';
import { IEvent } from '../event';

export interface INewEventInList {
  listTitle: string;
  event: IEvent;
}

export interface IEventInteraction {
  listTitle: string;
  event: IEvent | null;
  interaction: ListInteraction;
}

export interface IRemovedEventInList {
  listTitle: string;
  eventId: string;
}

export enum ListInteraction {
  UserJoined = 'UserJoined',
  UserNotJoining = 'UserNotJoining',
  UserAddedOnFavorite = 'UserAddedOnFavorite',
  UserRemovedFromFavorite = 'UserRemovedFromFavorite',
  UserAddedOnWailtlist = 'userAddedOnWailtist',
  UserRemovedFromWailtlist = 'userRemovedFromWailtlist',
}

@Injectable({
  providedIn: 'root',
})
export class EventListService {
  public newInteraction: Observable<IEventInteraction>;
  private _newInteraction: Subject<IEventInteraction>;

  constructor() {
    this._newInteraction = new Subject();
    this.newInteraction = this._newInteraction.asObservable();
  }

  public sendNewInteraction(interaction: IEventInteraction): void {
    this._newInteraction.next(interaction);
  }
}
