import { Injectable } from '@angular/core';
import { WindowReferenceService } from './window-ref.service';

@Injectable({
  providedIn: 'root',
})
export class DataLayerService {
  private window;

  constructor(private _windowRef: WindowReferenceService) {
    this.window = this._windowRef.nativeWindow; // intialise the window to what we get from our window service
  }

  public logPageView(pageEvent: any): void {
    const hit = {
      event: 'page-view',
      ...pageEvent
    };
    this.pingHome(hit);
  }

  public logObject(hit: any): void {
    this.pingHome(hit);
  }

  public logEvent(
    event: string,
    category: string,
    action: string,
    label: string,
  ): void {
    const hit = {
      event: event,
      category: category,
      action: action,
      label: label,
    };
    this.pingHome(hit);
  }

  public logCustomDimensionTest(value: any): void {
    const hit = {
      event: 'custom-dimension',
      value: value,
    };
    this.pingHome(hit);
  }

  private pingHome(obj: any) {
    if (obj) this.window.dataLayer.push(obj);
  }
}
